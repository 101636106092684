<h1 mat-dialog-title>Hola {{ data.names }} {{ data.lastnames}}</h1>
<div mat-dialog-content>
    <h3 class="text-center">Usted Confirma que los Datos que ha ingresado son Correctos</h3>
    <h5>Usted ha ingresado los siguientes Datos</h5>
    <div class="row">
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Nombres y Apellidos">people</mat-icon>
            <strong>  Nombres y Apellidos:</strong> {{ data.names }} {{ data.lastnames}}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Número de Documento">credit_card</mat-icon>
            <strong>  Número de Documento:</strong> {{ data.document }}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Número de Celular">settings_cell</mat-icon>
            <strong>  Número de Celular:</strong> {{ data.cellphone }}</p>
        <p class="col-12 col-md-6">
            <mat-icon aria-hidden="false" aria-label="Correo Electronico">email</mat-icon>
            <strong>  Correo Electrónico:</strong> {{ data.email }}</p>
        <p class="col-12 col-md-6" *ngIf="data.ie">
            <mat-icon aria-hidden="false" aria-label="Institución Educativa">domain</mat-icon>
            <strong>  Institución Educativa:</strong> {{ data.ie?.deDescripcion }}</p>
        <p class="col-12 col-md-6" [ngSwitch]="data.role">
            <mat-icon aria-hidden="false" aria-label="Cargo">groups</mat-icon>
            <strong>  Cargo:</strong>
            <span *ngSwitchCase="142">
            Auxiliar
    </span>
            <span *ngSwitchCase="162">
            Rector
    </span>
            <span *ngSwitchCase="202">
            Contador Secretaria
    </span>
            <span *ngSwitchCase="300">
            {{ data.otherRole }}
    </span>
        </p>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="legal__terms">
                <div class="legal__terms-scroll">
                    <h3 dir="ltr" style="line-height:1.68;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:13.999999999999998pt;font-family:'Fira Sans', sans-serif;color:#0a0a0a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Pol&iacute;tica de privacidad y tratamiento de datos personales</span></h3>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ADFI es un producto de Infogex S.A.S., que en adelante se denominar&aacute; ADFI. ADFI con domicilio en Tunja, es la sociedad responsable de sus datos personales y por tanto de la base de datos en la cual se encuentren ubicados los mismos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. SOBRE SUS DATOS PERSONALES.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para acceder a los servicios y beneficios de los espacios de Interacci&oacute;n de &ldquo;ADFI&rdquo;, y poder interactuar en ellos, deber&aacute; suministrar voluntaria y libremente, sus datos ciertos, no sensibles de identificaci&oacute;n personal, como son: nombre, apellidos, identificaci&oacute;n, tel&eacute;fono, correo electr&oacute;nico y dem&aacute;s datos necesarios que le sean solicitados en el proceso de registro.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. SEGURIDAD DE LA INFORMACI&Oacute;N.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;ADFI&rdquo; se esfuerza en proteger a sus usuarios como a nosotros mismos del acceso no autorizado o cualquier modificaci&oacute;n, divulgaci&oacute;n o destrucci&oacute;n no autorizada de la informaci&oacute;n que poseemos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La transmisi&oacute;n de datos a trav&eacute;s de Internet o cualquier red inal&aacute;mbrica no puede garantizarse que sea 100% segura. Como resultado de ello, al mismo tiempo que tratamos de proteger su informaci&oacute;n personal, no podemos asegurar o garantizar la seguridad de cualquier informaci&oacute;n que usted transmita a &ldquo;ADFI&rdquo;, por lo que usted lo hace bajo su riesgo.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En particular:</span></p>
                    <ul style="margin-top:0;margin-bottom:0;">
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Encriptamos algunos de nuestros servicios usando el protocolo de seguridad SSL.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Poseemos ciertos controles sobre nuestros sistemas de informaci&oacute;n que nos permite mantener una mejor calidad de su informaci&oacute;n.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Revisamos nuestras pr&aacute;cticas de recopilaci&oacute;n, almacenamiento y procesamiento de informaci&oacute;n, incluidas nuestras medidas de seguridad f&iacute;sicas, para protegernos frente al acceso no autorizado a los sistemas.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Restringimos el acceso a la informaci&oacute;n personal que poseemos de nuestros clientes y/o usuarios, a los empleados y contratistas que necesiten conocer tal informaci&oacute;n para procesarla por nosotros, y que est&eacute;n sujetos a estrictas obligaciones contractuales de confidencialidad y puedan ser sancionados o despedidos si no cumplen con estas obligaciones.</span></p>
                        </li>
                    </ul>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. ALMACENAMIENTO DE SUS DATOS PERSONALES.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3.1.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Los datos personales de todos nuestros clientes y/o usuarios se almacenar&aacute;n en una base de datos que se encuentra detr&aacute;s de un firewall para mayor seguridad. El servidor en donde reposa la base de datos est&aacute; protegido f&iacute;sicamente en un lugar seguro. S&oacute;lo personal autorizado puede acceder a &eacute;l y por tanto a los datos personales de nuestros clientes y/o usuarios.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. RECEPCI&Oacute;N DE SU INFORMACI&Oacute;N / USO DE SU INFORMACI&Oacute;N.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.1.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;La informaci&oacute;n privada de los usuarios y/o clientes en principio est&aacute; destinada a la prestaci&oacute;n del servicio para el cual este se vincul&oacute;.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.2.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Cuando usted se registra para poder participar de los diferentes espacios de Interacci&oacute;n de &ldquo;ADFI&rdquo; se le pide en el proceso informaci&oacute;n b&aacute;sica como nombres, apellidos, ocupaci&oacute;n, tel&eacute;fono entre otros.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.3.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Cuando env&iacute;a una solicitud de asistencia y/o reclamo es posible que le pidamos informaci&oacute;n personal.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.4.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Puede que le pidamos informaci&oacute;n personal en otros momentos, usted es libre de proporcionarla o no.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.5.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En general, no vamos a revelar su informaci&oacute;n personal a terceras partes no afiliadas sin su consentimiento expreso.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.6.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Puede haber circunstancias en las que es posible que tengamos que revelar informaci&oacute;n personal sobre un usuario y/o cliente. Estas razones son: El empleo de otras empresas para realizar funciones en nuestro nombre, tales como el cumplimiento de pedidos, proporcionar servicio al cliente, env&iacute;o de correo electr&oacute;nico y correspondencia, procesamiento de pagos con tarjeta de cr&eacute;dito, procesamiento de conversiones de moneda, contrataci&oacute;n de servidores ubicados dentro o fuera del Pa&iacute;s (Colombia), u otras funciones necesarias para nuestro negocio.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.7.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Las obligaciones incluidas en estas pr&aacute;cticas sobre el cuidado o manejo que &ldquo;ADFI&rdquo; le debe dar a la informaci&oacute;n personal de sus clientes y/o usuarios no aplican cuando la informaci&oacute;n sea requerida por una entidad p&uacute;blica o administrativa en ejercicio de sus funciones legales o por orden judicial.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.8.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;La Informaci&oacute;n proporcionada por Los clientes y/o Usuarios, podr&aacute; ser usada por &ldquo;ADFI&rdquo; de acuerdo con lo establecido en estas pr&aacute;cticas y con el fin de: (i) Proveer servicios y productos requeridos; (ii) Informar sobre nuevos productos o servicios que est&eacute;n relacionados o no con el contratado o adquirido por el cliente; (iii) Dar cumplimiento a obligaciones contra&iacute;das con nuestros clientes; (iv) Informar sobre cambios de nuestros productos o servicios; (v) Evaluar la calidad del servicio, (vi) Realizar estudios internos sobre h&aacute;bitos de consumo, (vii) Enviar al correo f&iacute;sico, electr&oacute;nico, celular o dispositivo m&oacute;vil, - v&iacute;a mensajes de texto (SMS y/o MMS) informaci&oacute;n comercial, publicitaria o promocional sobre los productos y/o servicios, eventos y/o promociones de tipo comercial o no de estas, con el fin de impulsar, invitar, dirigir, ejecutar, informar y de manera general, llevar a cabo campa&ntilde;as, promociones o concursos de car&aacute;cter comercial o publicitario. (viii) Compartir, incluyendo la transferencia y transmisi&oacute;n de sus datos personales a terceros pa&iacute;ses para los fines relacionados con la operaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4.9.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En caso de una venta de la mayor&iacute;a de los activos de la empresa, la informaci&oacute;n de los clientes y/o usuarios puede ser transferida al comprador como parte del establecimiento de comercio;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">5. EMPLEO DE COOKIES.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&ldquo;ADFI&rdquo; podr&aacute; utilizar cookies para personalizar y facilitar al m&aacute;ximo la navegaci&oacute;n del USUARIO por su Site. Las cookies se asocian &uacute;nicamente a un USUARIO an&oacute;nimo y su ordenador y no proporcionan referencias que permitan deducir datos personales del USUARIO. El USUARIO podr&aacute; configurar su navegador para que notifique y rechace la instalaci&oacute;n de las cookies enviadas por ADFI, sin que ello perjudique la posibilidad del USUARIO de acceder a los Contenidos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">6. ENLACES.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En el caso de que en el Sitio se dispusiesen enlaces o hiperv&iacute;nculos hac&iacute;a otros sitios de Internet, ADFI no ejercer&aacute; ning&uacute;n tipo de control sobre dichos sitios y contenidos. En ning&uacute;n caso ADFI asumir&aacute; responsabilidad alguna por los contenidos de alg&uacute;n enlace perteneciente a un sitio web ajeno, ni garantizar&aacute; la disponibilidad t&eacute;cnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o informaci&oacute;n contenida en ninguno de dichos hiperv&iacute;nculos u otros sitios de Internet. Igualmente la inclusi&oacute;n de estas conexiones externas no implicar&aacute; ning&uacute;n tipo de asociaci&oacute;n, fusi&oacute;n o participaci&oacute;n con las entidades conectadas.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">7. CONOCIMIENTO Y RECTIFICACI&Oacute;N DE INFORMACI&Oacute;N PERSONAL DE LOS USUARIOS.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En cualquier momento los clientes y/o usuarios podr&aacute;n solicitarle a &ldquo;ADFI&rdquo; como responsable de los datos personales, que proporcione informaci&oacute;n sobre los mismos, que los actualice, los rectifique, as&iacute; mismo podr&aacute; incluir nuevos datos si es su deseo, suprimirlos o excluirlos de la base de datos salvo que el dato sea absolutamente necesario para la prestaci&oacute;n del servicio al cual se encuentra registrado y/o inscrito el cliente y/o usuario y quiera permanecer en &eacute;l, de lo contrario, se desvincular&aacute; y/o inactivara totalmente del mismo.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si el usuario y/o cliente desea ejercer sus derechos y por tanto actualizar, rectificar suprimir entre otros deber&aacute; enviar un correo electr&oacute;nico a contactenos@infogex.co</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">8. DERECHOS DE LOS CLIENTES Y/O USUARIOS (1581 DE 2012, ART&Iacute;CULO 8).</span></p>
                    <ul style="margin-top:0;margin-bottom:0;">
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conocer sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podr&aacute; ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento est&eacute; expresamente prohibido o no haya sido autorizado;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Solicitar prueba de la autorizaci&oacute;n otorgada al Responsable del Tratamiento salvo cuando expresamente se except&uacute;e como requisito para el Tratamiento, de conformidad con lo previsto en el art&iacute;culo 10 de la presente ley;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos personales;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la presente ley y las dem&aacute;s normas que la modifiquen, adicionen o complementen;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato cuando en el Tratamiento no se respeten los principios, derechos y garant&iacute;as constitucionales y legales. La revocatoria y/o supresi&oacute;n proceder&aacute; cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a esta ley y a la Constituci&oacute;n;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:disc;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</span></p>
                        </li>
                    </ul>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">9. VIGENCIA.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El cliente y/o Usuario acepta y reconoce que esta autorizaci&oacute;n estar&aacute; vigente a partir del momento en que la acept&oacute; y durante el tiempo en que Infogex S.A.S. Ejerza las actividades propias de su objeto social.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">10. ATENCI&Oacute;N AL CLIENTE.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para consultas, reclamos, quejas o para el ejercicio de los derechos del usuario o cliente, podr&aacute; comunicarse con &ldquo;ADFI&rdquo; al correo electr&oacute;nico contactenos@infogex.co.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Una vez el Usuario y/o cliente presente una solicitud de las mencionadas anteriormente, &ldquo;ADFI&rdquo; tendr&aacute; hasta quince (15) d&iacute;as h&aacute;biles. En todo caso &ldquo;ADFI&rdquo; podr&aacute; sujetarse a los t&eacute;rminos establecidos en la ley.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">11. RESPONSABLE DEL TRATAMIENTO DE MIS DATOS</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">NIT. 800.063.594-5</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Direcci&oacute;n oficinas: Avenida Universitaria No. 45-102 Oficina 104 Edificio Mil&aacute;n- Tunja</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Correo electr&oacute;nico: contactenos@infogex.co</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tel&eacute;fono: &nbsp;7452923 - 7471193</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Celular: &nbsp;3163554130 - 3046055033</span></p>
                    <p><br></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S. puede cambiar unilateralmente su pol&iacute;tica de privacidad y de uso de datos personales. No limitaremos sus derechos derivados de la presente Pol&iacute;tica de privacidad y de uso de datos personales sin su expreso consentimiento. Publicaremos cualquier modificaci&oacute;n de la presente Pol&iacute;tica de privacidad y de uso de datos personales en esta p&aacute;gina. Adem&aacute;s, conservaremos las versiones anteriores de la presente Pol&iacute;tica de privacidad y de uso de datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Este servicio, no est&aacute; dirigido a ni&ntilde;os, ni&ntilde;as y adolescentes por lo que se le solicita, abstenerse de seguir adelante con el registro y/o servicio en caso de que usted sea una persona menor de dieciocho (18) a&ntilde;os.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">12. &iquest;COMO ACCEDER, RECTIFICAR Y ACTUALIZAR SUS DATOS PERSONALES O SOLICITAR LA SUPRESI&Oacute;N DE LOS MISMOS?</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Usted tiene derecho a acceder a sus datos personales y a los detalles del tratamiento de los mismos, as&iacute; como a rectificarlos actualizarlos en caso de ser inexactos o a solicitar su eliminaci&oacute;n cuando considere que resulten ser excesivos o innecesarios para las finalidades que justificaron su obtenci&oacute;n u oponerse al tratamiento de los mismos para fines espec&iacute;ficos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para ejercer el referido derecho debe tener en cuenta lo siguiente:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La dependencia encargada de los asuntos vinculados a la protecci&oacute;n de datos es el Departamento de MESA DE AYUDA ubicada en la Avenida Universitaria No. 45-102 Oficina 104 Edificio Mil&aacute;n de Tunja.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La direcci&oacute;n electr&oacute;nica: contactenos@infogex.co</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para mayor informaci&oacute;n, favor de comunicarse al Tel&eacute;fono 7452923 - 747119 o celular 3163554130 - 3046055033, a nivel nacional 01 8000 51 00 51.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">13. MANUAL INTERNO DE POLITICAS Y PROCEDIMIENTOS EN MATERIA DE PROTECCION DE DATOS PERSONALES</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAP&Iacute;TULO I</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DISPOCIONES GENERALES</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 1. LEGISLACI&Oacute;N APLICABLE.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Este manual fue elaborado teniendo en cuenta las disposiciones contenidas en los art&iacute;culos 15 y 20 de la Constituci&oacute;n Pol&iacute;tica, la Ley 1581 de 2012 &ldquo;Por la cual se dictan disposiciones generales para la protecci&oacute;n de datos personales&rdquo;.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ARTICULO 2. AMBITO DE APLICACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Este manual se aplica al tratamiento de los datos de car&aacute;cter personal que recoja y maneje Infogex S.A.S.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 3. BASES DE DATOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Las pol&iacute;ticas y procedimientos contenidos en el presente manual aplican a las bases de datos de cada unidad de negocios existente y que llegare a existir, los cuales podr&aacute;n consistir pero no necesariamente limitados a ellos, los siguientes: Editoriales, Suscripciones, Foros, Publicidad, Medios Electr&oacute;nicos, Distribuci&oacute;n, N&oacute;mina, Facturaci&oacute;n, Cobranzas, Contabilidad cuyo per&iacute;odo de vigencia es el mismo que tenga de duraci&oacute;n la sociedad Infogex S.A.S. registrada ante la C&aacute;mara de Comercio de Bogot&aacute;.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 4. OBJETO.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Por medio del presente manual se da cumplimiento a lo previsto en el literal k) del art&iacute;culo 17 de la Ley 1581 de 2012, que regula los deberes que asisten a los responsables del tratamiento de datos personales, dentro de los cuales se encuentra el de adoptar un manual interno de pol&iacute;ticas y procedimientos para garantizar el adecuado cumplimiento de la ley y en especial, para la atenci&oacute;n de consultas y reclamos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">As&iacute; mismo tiene la finalidad de regular los procedimientos de recolecci&oacute;n, manejo y tratamiento de los datos de car&aacute;cter personal que realiza Infogex S.A.S., a fin de garantizar y proteger el derecho fundamental de habeas data en el marco de lo establecido en la misma ley.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 5. DEFINICIONES:&nbsp;</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para efectos de la aplicaci&oacute;n de las reglas contenidas en el presente manual y de acuerdo con lo establecido en el art&iacute;culo 3 de la Ley 1581 de 2012 se entiende por:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Autorizaci&oacute;n: Consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de datos personales;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Aviso de privacidad: Documento f&iacute;sico, electr&oacute;nico o en cualquier otro formato generado por el Responsable que se pone a disposici&oacute;n del Titular para el tratamiento de sus datos personales. En el Aviso de Privacidad se comunica al Titular la informaci&oacute;n relativa a la existencia de las pol&iacute;ticas de tratamiento de informaci&oacute;n que le ser&aacute;n aplicables, la forma de acceder a las mismas y las caracter&iacute;sticas del tratamiento que se pretende dar a los datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Base de Datos: Conjunto organizado de datos personales que sea objeto de Tratamiento.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato personal: Cualquier informaci&oacute;n vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Dato privado: Es el dato que por su naturaleza &iacute;ntima o reservada s&oacute;lo es relevante para el titular.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Datos sensibles: Se entiende por datos sensibles aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar su discriminaci&oacute;n, tales como aquellos que revelen el origen racial o &eacute;tnico, la orientaci&oacute;n pol&iacute;tica, las convicciones religiosas o filos&oacute;ficas, la pertenencia a sindicatos, organizaciones sociales, de derechos humanos o que promueva intereses de cualquier partido pol&iacute;tico o que garanticen los derechos y garant&iacute;as de partidos pol&iacute;ticos de oposici&oacute;n, as&iacute; como los datos relativos a la salud, a la vida sexual y los datos biom&eacute;tricos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Encargado del Tratamiento: Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, realice el Tratamiento de datos personales por cuenta del Responsable del Tratamiento;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Responsable del Tratamiento: Persona natural o jur&iacute;dica, p&uacute;blica o privada, que por s&iacute; misma o en asocio con otros, decida sobre la base de datos y/o el Tratamiento de los datos;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Titular: Persona natural cuyos datos personales sean objeto de Tratamiento;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tratamiento: Cualquier operaci&oacute;n o conjunto de operaciones sobre datos personales, tales como la recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n de los mismos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 6. PRINCIPIOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Los principios que se establecen a continuaci&oacute;n, constituyen los par&aacute;metros generales que ser&aacute;n respetados por Infogex S.A.S. en los procesos de acopio, uso y tratamiento de datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de finalidad: El Tratamiento de los datos personales recogidos por los Infogex S.A.S. debe obedecer a una finalidad leg&iacute;tima de la cual debe ser informada al Titular;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de libertad: El Tratamiento s&oacute;lo puede llevarse a cabo con el consentimiento, previo, expreso e informado del Titular. Los datos personales no podr&aacute;n ser obtenidos o divulgados sin previa autorizaci&oacute;n, o en ausencia de mandato legal o judicial que releve el consentimiento;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de veracidad o calidad: La informaci&oacute;n sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se proh&iacute;be el Tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de transparencia: En el Tratamiento debe garantizarse el derecho del Titular a obtener de Infogex S.A.S. en cualquier momento y sin restricciones, informaci&oacute;n acerca de la existencia de datos que le conciernan;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de acceso y circulaci&oacute;n restringida: Los datos personales, salvo la informaci&oacute;n p&uacute;blica, no podr&aacute;n estar disponibles en Internet u otros medios de divulgaci&oacute;n o comunicaci&oacute;n masiva, salvo que el acceso sea t&eacute;cnicamente controlable para brindar un conocimiento restringido s&oacute;lo a los Titulares o terceros autorizados;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de seguridad: La informaci&oacute;n sujeta a Tratamiento por parte de Infogex S.A.S., se deber&aacute; proteger mediante el uso de las medidas t&eacute;cnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Principio de confidencialidad: Todas las personas que intervengan en el Tratamiento de datos personales est&aacute;n obligadas a garantizar la reserva de la informaci&oacute;n, inclusive despu&eacute;s de finalizada su relaci&oacute;n con alguna de las labores que comprende el Tratamiento.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAP&Iacute;TULO II</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">AUTORIZACI&Oacute;N</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 7. AUTORIZACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;La recolecci&oacute;n, almacenamiento, uso, circulaci&oacute;n o supresi&oacute;n de datos personales por parte de Infogex S.A.S. requiere del consentimiento libre, previo, expreso e informado del titular de los mismos. Infogex S.A.S., en su condici&oacute;n de responsable del tratamiento de datos personales, ha dispuesto de los mecanismos necesarios para obtener la autorizaci&oacute;n de los titulares garantizando en todo caso que sea posible verificar el otorgamiento de dicha autorizaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 8. FORMA Y MECANISMOS PARA OTORGAR LA AUTORIZACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;La autorizaci&oacute;n puede constar en un documento f&iacute;sico, electr&oacute;nico o en cualquier otro formato o medio que permita garantizar su posterior consulta. La autorizaci&oacute;n ser&aacute; emitida por Infogex S.A.S. y ser&aacute; puesta a disposici&oacute;n del titular previo al tratamiento de sus datos personales, de conformidad con lo que establece la Ley 1581 de 2102.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Con el procedimiento de autorizaci&oacute;n consentida se garantiza que se ha puesto en conocimiento del titular de los datos personales, tanto el hecho que su informaci&oacute;n personal ser&aacute; recogida y utilizada para fines determinados y conocidos, como que tiene la opci&oacute;n de conocer cualquier alternaci&oacute;n a los mismos y el uso espec&iacute;fico que de ellos se ha dado. Lo anterior con el fin de que el titular tome decisiones informadas con relaci&oacute;n a sus datos personales y controle el uso de su informaci&oacute;n personal. La autorizaci&oacute;n es una declaraci&oacute;n que informa al titular de los datos personales:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Qui&eacute;n recopila (responsable o encargado)</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Qu&eacute; recopila (datos que se recaban)</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para qu&eacute; recoge los datos (las finalidades del tratamiento)</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">C&oacute;mo ejercer derechos de acceso, correcci&oacute;n, actualizaci&oacute;n o supresi&oacute;n de los datos personales suministrados</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Si se recopilan datos sensibles</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 9. PRUEBA DE LA AUTORIZACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. Adoptar&aacute; las medidas necesarias para mantener registros de cu&aacute;ndo y c&oacute;mo obtuvieron autorizaci&oacute;n por parte de los titulares de datos personales para el tratamiento de los mismos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 10. AVISO DE PRIVACIDAD:</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El Aviso de Privacidad es el documento f&iacute;sico, electr&oacute;nico o en cualquier otro formato, que es puesto a disposici&oacute;n del Titular para el tratamiento de sus datos personales. A trav&eacute;s de este documento se informa al Titular la informaci&oacute;n relativa a la existencia de las pol&iacute;ticas de tratamiento de informaci&oacute;n que le ser&aacute;n aplicables, la forma de acceder a las mismas y las caracter&iacute;sticas del tratamiento que se pretende dar a los datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 11. CONTENIDO M&Iacute;NIMO DEL AVISO DE PRIVACIDAD.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El Aviso de Privacidad, como m&iacute;nimo, deber&aacute; contener la siguiente informaci&oacute;n:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La identidad, domicilio y datos de contacto del Responsable del Tratamiento; El tipo de tratamiento al cual ser&aacute;n sometidos los datos y la finalidad del mismo, y Los mecanismos generales dispuestos por el Responsable para que el Titular conozca la pol&iacute;tica de tratamiento de la informaci&oacute;n y los cambios sustanciales que se produzcan en ella. En todos los casos, debe informar al titular c&oacute;mo acceder o consultar la pol&iacute;tica de tratamiento de informaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 12. AVISO DE PRIVACIDAD Y LAS POL&Iacute;TICAS DE TRATAMIENTO DE LA INFORMACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. conservar&aacute; el modelo del aviso de privacidad que se transmiti&oacute; a los Titulares mientras se lleve a acabo tratamiento de datos personales y perduren las obligaciones que de &eacute;ste deriven. Para el almacenamiento del modelo, Infogex S.A.S. podr&aacute; emplear medios inform&aacute;ticos, electr&oacute;nicos o cualquier otra tecnolog&iacute;a.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAPITULO III</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DERECHOS Y DEBERES</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 13. DERECHOS DE LOS TITULARES DE LA INFORMACI&Oacute;N.&nbsp;</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">De conformidad con lo establecido en el art&iacute;culo 8 de la Ley 1581 de 2012 el titular de los datos personales tiene los siguientes derechos:</span></p>
                    <ol style="margin-top:0;margin-bottom:0;">
                        <li dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conocer sus datos personales frente a Infogex S.A.S., en su condici&oacute;n de responsable del tratamiento.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Ser informado por el Infogex S.A.S. previa solicitud, respecto del uso que le ha dado a sus datos personales;</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012, una vez haya agotado el tr&aacute;mite de consulta o reclamo ante el Responsable del Tratamiento.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Revocar la autorizaci&oacute;n y/o solicitar la supresi&oacute;n del dato cuando en el Tratamiento no se respeten los principios, derechos y garant&iacute;as constitucionales y legales.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:lower-alpha;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.</span></p>
                        </li>
                    </ol>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 14. DEBERES DEL DIARIO EN RELACI&Oacute;N CON EL TRATAMIENTO DE LOS DATOS PERSONALES.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. tendr&aacute; presente, en todo momento, que los datos personales son propiedad de las personas a las que se refieren y que s&oacute;lo ellas pueden decidir sobre los mismos. En este sentido, har&aacute; uso de ellos s&oacute;lo para aquellas finalidades para las que se encuentra facultado debidamente, y respetando en todo caso la Ley 1581 de 2012 sobre protecci&oacute;n de datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">De conformidad con lo establecido en el art&iacute;culo 17 de la Ley 1581 de 2012 Infogex S.A.S. se compromete a cumplir en forma permanente con los siguientes deberes en lo relacionado con en el tratamiento de datos personales:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Garantizar al Titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de h&aacute;beas data;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Conservar la informaci&oacute;n bajo las condiciones de seguridad necesarias para impedir su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Realizar oportunamente, esto es en los t&eacute;rminos previstos en los art&iacute;culos 14 y 15 de la Ley 1581 de 2012, la actualizaci&oacute;n, rectificaci&oacute;n o supresi&oacute;n de los datos;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Tramitar las consultas y los reclamos formulados por los Titulares en los t&eacute;rminos se&ntilde;alados en el art&iacute;culo 14 de la Ley 1581 de 2012;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Abstenerse de circular informaci&oacute;n que est&eacute; siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Permitir el acceso a la informaci&oacute;n &uacute;nicamente a las personas que pueden tener acceso a ella;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Informar a la Superintendencia de Industria y Comercio cuando se presenten violaciones a los c&oacute;digos de seguridad y existan riesgos en la administraci&oacute;n de la informaci&oacute;n de los Titulares;</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cumplir las instrucciones y requerimientos que imparta la Superintendencia de Industria y Comercio.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAP&Iacute;TULO IV</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PROCEDIMIENTOS DE ACCESO, CONSULTA Y RECLAMACI&Oacute;N</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 15. DERECHO DE ACCESO:</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El poder de disposici&oacute;n o decisi&oacute;n que tiene el titular sobre la informaci&oacute;n que le concierne, conlleva necesariamente el derecho de acceder y conocer si su informaci&oacute;n personal est&aacute; siendo objeto de tratamiento, as&iacute; como el alcance, condiciones y generalidades de dicho tratamiento. De esta manera, Infogex S.A.S. debe garantizar al titular su derecho de acceso en tres v&iacute;as:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La primera implica que el titular pueda conocer la efectiva existencia del tratamiento a que son sometidos sus datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La segunda, que el titular pueda tener acceso a sus datos personales que est&aacute;n en posesi&oacute;n del responsable.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La tercera, supone el derecho a conocer las circunstancias esenciales del tratamiento, lo cual se traduce en el deber de Infogex S.A.S. de informar al titular sobre el tipo de datos personales tratados y todas y cada una de las finalidades que justifican el tratamiento.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">PARAGRAFO:</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. garantizar&aacute; el derecho de acceso cuando, previa acreditaci&oacute;n de la identidad del titular o personer&iacute;a de su representante, se ponga a disposici&oacute;n de &eacute;ste, de manera gratuita, el detalle de los datos personales a trav&eacute;s de medios electr&oacute;nicos que permitan el acceso directo del Titular a ellos. Dicho acceso deber&aacute; ofrecerse sin l&iacute;mite de plazo y deber&aacute; permitir al titular la posibilidad de conocerlos y actualizarlos en l&iacute;nea.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 16 CONSULTAS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;De conformidad con lo establecido en el art&iacute;culo 14 de la Ley 1581 de 2012 los titulares o sus causahabientes podr&aacute;n consultar la informaci&oacute;n personal del Titular que repose en cualquier base de datos. En consecuencia, Infogex S.A.S. garantizar&aacute; el derecho de consulta, suministrando a los titulares, toda la informaci&oacute;n contenida en el registro individual o que est&eacute; vinculada con la identificaci&oacute;n del Titular.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Para la atenci&oacute;n de solicitudes de consulta de datos personales Infogex S.A.S. garantiza:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Habilitar medios de comunicaci&oacute;n electr&oacute;nica u otros que considere pertinentes.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Establecer formularios, sistemas y otros m&eacute;todos simplificados, mismos que deben ser informados en el aviso de privacidad.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Utilizar los servicios de atenci&oacute;n al cliente o de reclamaciones que tiene en operaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En cualquier caso, independientemente del mecanismo implementado para la atenci&oacute;n de solicitudes de consulta, las mismas ser&aacute;n atendidas en un t&eacute;rmino m&aacute;ximo de diez (10) d&iacute;as h&aacute;biles contados a partir de la fecha de su recibo. Cuando no fuere posible atender la consulta dentro de dicho t&eacute;rmino, se informar&aacute; al interesado antes del vencimiento de los diez (10) d&iacute;as, expresando los motivos de la demora y se&ntilde;alando la fecha en que se atender&aacute; su consulta, la cual en ning&uacute;n caso podr&aacute; superar los cinco (5) d&iacute;as h&aacute;biles siguientes al vencimiento del primer plazo.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 17. RECLAMOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;De conformidad con lo establecido en el art&iacute;culo 14 de la Ley 1581 de 2012, el Titular o sus causahabientes que consideren que la informaci&oacute;n contenida en una base de datos debe ser objeto de correcci&oacute;n, actualizaci&oacute;n o supresi&oacute;n, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la Ley 1581 de 2012, podr&aacute;n presentar un reclamo ante el Responsable del Tratamiento, el cual ser&aacute; tramitado bajo las siguientes reglas:</span></p>
                    <ol style="margin-top:0;margin-bottom:0;">
                        <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El reclamo lo podr&aacute; presentar el Titular en la mesa de ayuda. Si el reclamo recibido no cuenta con informaci&oacute;n completa que permita darle tr&aacute;mite, esto es, con la identificaci&oacute;n del Titular, la descripci&oacute;n de los hechos que dan lugar al reclamo, la direcci&oacute;n, y acompa&ntilde;ando los documentos que se quiera hacer valer, se requerir&aacute; al interesado dentro de los cinco (5) d&iacute;as siguientes a su recepci&oacute;n para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento sin que el solicitante presente la informaci&oacute;n requerida, se entender&aacute; que ha desistido del reclamo. Si por alguna circunstancia Infogex S.A.S. recibe un reclamo que en realidad no deber&iacute;a ir dirigido contra &eacute;l, dar&aacute; traslado a quien corresponda en un t&eacute;rmino m&aacute;ximo de dos (3) d&iacute;as h&aacute;biles e informar&aacute; de la situaci&oacute;n al interesado.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:0pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Una vez recibido el reclamo completo, se incluir&aacute; en la base de datos que mantiene Infogex S.A.S. una leyenda que diga &quot;reclamo en tr&aacute;mite&quot; y el motivo del mismo, en un t&eacute;rmino no mayor a dos (3) d&iacute;as h&aacute;biles. Dicha leyenda deber&aacute; mantenerse hasta que el reclamo sea decidido.</span></p>
                        </li>
                        <li dir="ltr" style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;">
                            <p dir="ltr" style="line-height:1.56;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El t&eacute;rmino m&aacute;ximo para atender el reclamo ser&aacute; de quince (15) d&iacute;as h&aacute;biles contados a partir del d&iacute;a siguiente a la fecha de su recibo. Cuando no fuere posible atenderlo dentro de dicho t&eacute;rmino se informar&aacute; al interesado antes del vencimiento del referido plazo los motivos de la demora y la fecha en que se atender&aacute; su reclamo, la cual en ning&uacute;n caso podr&aacute; superar los ocho (8) d&iacute;as h&aacute;biles siguientes al vencimiento del primer t&eacute;rmino.</span></p>
                        </li>
                    </ol>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 18. IMPLEMENTACI&Oacute;N DE PROCEDIMIENTOS PARA GARANTIZAR EL DERECHO A PRESENTAR RECLAMOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En cualquier momento y de manera gratuita el titular o su representante podr&aacute;n solicitar a Infogex S.A.S. la rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n de sus datos personales, previa acreditaci&oacute;n de su identidad.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los derechos de rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n &uacute;nicamente se podr&aacute;n ejercer por:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El titular o sus causahabientes, previa acreditaci&oacute;n de su identidad, o a trav&eacute;s de instrumentos electr&oacute;nicos que le permitan identificarse. Su representante, previa acreditaci&oacute;n de la representaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cuando la solicitud sea formulada por persona distinta del titular y no se acredite que la misma act&uacute;a en representaci&oacute;n de aqu&eacute;l, se tendr&aacute; por no presentada.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La solicitud de rectificaci&oacute;n, actualizaci&oacute;n o supresi&oacute;n debe ser presentada a trav&eacute;s de los medios habilitados por el Infogex S.A.S. se&ntilde;alados en el aviso de privacidad y contener, como m&iacute;nimo, la siguiente informaci&oacute;n:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El nombre y domicilio del titular o cualquier otro medio para recibir la respuesta</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los documentos que acrediten la identidad o la personalidad de su representante.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La descripci&oacute;n clara y precisa de los datos personales respecto de los cuales el titular busca ejercer alguno de los derechos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En caso dado otros elementos o documentos que faciliten la localizaci&oacute;n de los datos personales.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Par&aacute;grafo 1. RECTIFICACI&Oacute;N Y ACTUALIZACI&Oacute;N DE DATOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. tiene la obligaci&oacute;n de rectificar y actualizar a solicitud del titular, la informaci&oacute;n de &eacute;ste que resulte ser incompleta o inexacta, de conformidad con el procedimiento y los t&eacute;rminos arriba se&ntilde;alados. Al respecto se tendr&aacute; en cuenta lo siguiente:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En las solicitudes de rectificaci&oacute;n y actualizaci&oacute;n de datos personales el titular debe indicar las correcciones a realizar y aportar la documentaci&oacute;n que avale su petici&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S. tiene plena libertad de habilitar mecanismos que le faciliten el ejercicio de este derecho, siempre y cuando &eacute;stos beneficien al titular. En consecuencia, se podr&aacute;n habilitar medios electr&oacute;nicos u otros que considere pertinentes.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S. podr&aacute; establecer formularios, sistemas y otros m&eacute;todos simplificados, mismos que deben ser informados en el aviso de privacidad y que se pondr&aacute;n a disposici&oacute;n de los interesados en la p&aacute;gina web.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S. utilizar&aacute; los servicios de atenci&oacute;n o servicio al cliente que tiene en operaci&oacute;n, siempre y cuando los plazos de respuesta no sean mayores a los se&ntilde;alados por el art&iacute;culo 15 de la Ley 1581 de 2012.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Cada vez que Infogex S.A.S. ponga a disposici&oacute;n una herramienta nueva para facilitar el ejercicio de sus derechos por parte de los titulares de informaci&oacute;n o modifique las existentes, lo informar&aacute; a trav&eacute;s de su p&aacute;gina web.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Par&aacute;grafo 2. SUPRESI&Oacute;N DE DATOS.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El titular tiene el derecho, en todo momento, a solicitar a Infogex S.A.S. la supresi&oacute;n (eliminaci&oacute;n) de sus datos personales cuando:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Considere que los mismos no est&aacute;n siendo tratados conforme a los principios, deberes y obligaciones previstas en la Ley 1581 de 2012.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Hayan dejado de ser necesarios o pertinentes para la finalidad para la cual fueron recabados.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Se haya superado el periodo necesario para el cumplimiento de los fines para los que fueron recabados.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Esta supresi&oacute;n implica la eliminaci&oacute;n total o parcial de la informaci&oacute;n personal de acuerdo con lo solicitado por el titular en los registros, archivos, bases de datos o tratamientos realizados por el Infogex S.A.S. Es importante tener en cuenta que el derecho de cancelaci&oacute;n no es absoluto y el responsable puede negar el ejercicio del mismo cuando:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La solicitud de supresi&oacute;n de la informaci&oacute;n no proceder&aacute;n cuando el titular tenga un deber legal o contractual de permanecer en la base de datos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">La eliminaci&oacute;n de datos obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigaci&oacute;n y persecuci&oacute;n de delitos o la actualizaci&oacute;n de sanciones administrativas.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los datos sean necesarios para proteger los intereses jur&iacute;dicamente tutelados del titular; para realizar una acci&oacute;n en funci&oacute;n del inter&eacute;s p&uacute;blico, o para cumplir con una obligaci&oacute;n legalmente adquirida por el titular.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">En caso de resultar procedente la cancelaci&oacute;n de los datos personales, Infogex S.A.S. debe realizar operativamente la supresi&oacute;n de tal manera que la eliminaci&oacute;n no permita la recuperaci&oacute;n de la informaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 19. REVOCATORIA DE LA AUTORIZACI&Oacute;N.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Los titulares de los datos personales pueden revocar el consentimiento al tratamiento de sus datos personales en cualquier momento, siempre y cuando no lo impida una disposici&oacute;n legal. Para ello, Infogex S.A.S. deber&aacute; establecer mecanismos sencillos y gratuitos que permitan al titular revocar su consentimiento, al menos por el mismo medio por el que lo otorg&oacute;.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Se deber&aacute; tener en cuenta que existen dos modalidades en las que la revocaci&oacute;n del consentimiento puede darse. La primera, puede ser sobre la totalidad de las finalidades consentidas, esto es, que Infogex S.A.S. deba dejar de tratar por completo los datos del titular; la segunda, puede ocurrir sobre tipos de tratamiento determinados, como por ejemplo para fines publicitarios o de estudios de mercado. Con la segunda modalidad, esto es, la revocaci&oacute;n parcial del consentimiento, se mantienen a salvo otros fines del tratamientos que el responsable, de conformidad con la autorizaci&oacute;n otorgada puede llevar a cabo y con los que el titular est&aacute; de acuerdo.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Por lo anterior, ser&aacute; necesario que el titular al momento elevar la solicitud de revocatoria consentimiento a Infogex S.A.S., indique en &eacute;sta si la revocaci&oacute;n que pretende realizar es total o parcial. En la segunda hip&oacute;tesis se deber&aacute; indicar con cu&aacute;l tratamiento el titular no est&aacute; conforme.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Habr&aacute; casos en que el consentimiento, por su car&aacute;cter necesario en la relaci&oacute;n entre titular y responsable por el cumplimiento de un contrato, por disposici&oacute;n legal no podr&aacute; ser revocado.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Los mecanismos o procedimientos que Infogex S.A.S. establezca para atender las solicitudes de revocatoria del consentimiento no podr&aacute;n exceder los plazos previstos para atender las reclamaciones conforme se se&ntilde;ala en el art&iacute;culo 15 de la Ley 1581 de 2012.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAPITULO V</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">SEGURIDAD DE LA INFORMACI&Oacute;N</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 20. MEDIDAS DE SEGURIDAD.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;En desarrollo del principio de seguridad establecido en la Ley 1581 de 2012, Infogex S.A.S. adoptar&aacute; las medidas t&eacute;cnicas, humanas y administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteraci&oacute;n, p&eacute;rdida, consulta, uso o acceso no autorizado o fraudulento.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 21. IMPLEMENTACI&Oacute;N DE LAS MEDIDAS DE SEGURIDAD.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;Infogex S.A.S. mantendr&aacute; protocolos de seguridad de obligatorio cumplimiento para el personal con acceso a los datos de car&aacute;cter personal y a los sistemas de informaci&oacute;n.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El procedimiento deber&aacute; considerar, como m&iacute;nimo, los siguientes aspectos:</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&Aacute;mbito de aplicaci&oacute;n del procedimiento con especificaci&oacute;n detallada de los recursos protegidos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Medidas, normas, procedimientos, reglas y est&aacute;ndares encaminados a garantizar el nivel de seguridad exigido en la Ley 1581 de 2012</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Funciones y obligaciones del personal.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Estructura de las bases de datos de car&aacute;cter personal y descripci&oacute;n de los sistemas de informaci&oacute;n que los tratan.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Procedimiento de notificaci&oacute;n, gesti&oacute;n y respuesta ante las incidencias.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Procedimientos de realizaci&oacute;n de copias de respaldo y de recuperaci&oacute;n de los datos.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Controles peri&oacute;dicos que se deban realizar para verificar el cumplimiento de lo dispuesto en el procedimiento de seguridad que se implemente</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Medidas a adoptar cuando un soporte o documento vaya ha ser transportado, desechado o reutilizado.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El procedimiento deber&aacute; mantenerse actualizado en todo momento y deber&aacute; ser revisado siempre que se produzcan cambios relevantes en el sistema de informaci&oacute;n o en la organizaci&oacute;n del mismo.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">El contenido del procedimiento deber&aacute; adecuarse en todo momento a las disposiciones vigentes en materia de seguridad de los datos personales</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">CAP&Iacute;TULO VI</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">DISPOSICIONES FINALES</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 22.&nbsp;</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Infogex S.A.S. designa a FERNANDO BARRIOS, Coordinador de Mesa de Ayuda &ndash; Servicio al cliente en adici&oacute;n a otras funciones, para dar tr&aacute;mite a las solicitudes de los titulares, para el ejercicio de los derechos de acceso, consulta, rectificaci&oacute;n, actualizaci&oacute;n, supresi&oacute;n y revocatoria a que se refiere la Ley 1581 de 2012.</span></p>
                    <p dir="ltr" style="line-height:1.56;text-align: justify;margin-top:0pt;margin-bottom:12pt;"><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">ART&Iacute;CULO 23. VIGENCIA.</span><span style="font-size:12pt;font-family:Arial;color:#6e6e6e;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">&nbsp;El presente manual rige a partir del 9 de octubre de 2020.</span></p>
                    <p><br></p>
                    <p><br></p>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 text-center">
            <mat-checkbox class="example-margin" [checked]="allComplete"
                          style="width: auto"
                          [(ngModel)]="allComplete"
                          tabindex="-1"
                          [color]="'primary'">Confirmo haber leído y acepto la política de privacidad y el tratamiento
                de datos personales
            </mat-checkbox>
        </div>
    </div>
</div>
<div mat-dialog-actions class="d-flex align-content-around" tabindex="-1">
    <button type="button" color="primary" mat-raised-button
            [disabled]="!allComplete" (click)="confirm()" tabindex="-1" >
        <mat-icon aria-hidden="false" aria-label="Confirmar">done</mat-icon>
        CONFIRMAR INFORMACIÓN
    </button>
    <button tabindex="-1" type="button" mat-raised-button [mat-dialog-close]="data" (click)="onNoClick()">Corregir Información
    </button>
</div>
