import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavRoute} from '../../../utils/menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() items: NavRoute[];
  @Input() subMenu = false;
  @Output() clickedItem = new EventEmitter();

  customClass: string;

  ngOnInit() {
    this.customClass = this.subMenu ? 'submenu-item': '';
  }

}
