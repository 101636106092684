<div class="row justify-content-end">
  <div class="col text-right">
    <span class="valor">{{ valorTotal | currency }}</span>
    <button
      class="btn-action-currency"
      color="primary"
      mat-button
      matSuffix
      mat-icon-button
      (click)="update()"
      matTooltip="Actualizar"
      [disabled]="loading || (!source && customFilter === undefined)"
    >
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<div matRipple class="row tree-header" *ngIf="data && data.length">
  <div class="col-1 text-center">Rubro</div>
  <div class="col text-center">Descripción</div>
  <div class="col-4 text-center">Valor</div>
</div>
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="10" style="font-size: 12px">
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>

    <div class="d-flex w-100 align-items-center">
    <div class="pointer text-left mx-2">{{ node.minipresu }}</div>
    <div class="flex-grow-4"><span class="pointer">{{ node.nombre }}</span> {{ node.valueAvailable === undefined || node.valueAvailable === null ?  '' : ('('+(node.valueAvailable | currency)+')') }} </div>
    <div class="text-right mx-2">
      <ng-container *ngIf="!node.edit; else saveValTemplate">
        {{ node.valor | currency }}

        <button
          *ngIf="
            !node.estadoPresupuesto &&
            (node.descompuesto == 'N' || node.descompuesto == 'V') && edit && externalPermission
          "
          class="btn-action-currency"
          mat-button
          matSuffix
          mat-icon-button
          matTooltip="Editar"
          (click)="node.edit = true"
        >
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>
      <!-- edit -->
      <ng-template #saveValTemplate>
        <mat-form-field appearance="legacy">
          <span matPrefix class="prefix-inp-form">$</span>
          <input matInput [value]="node.valorIn" appCurrencyDirective (valueChange)="node.valor = $event" required [decimals]="decimals">
        </mat-form-field>
        <button
          class="btn-action-currency"
          mat-button
          *ngIf="node.valor >= 0"
          matSuffix
          mat-icon-button
          matTooltip="Guardar"
          (click)="save(node)"
        >
          <mat-icon>save</mat-icon>
        </button>
        <button
          class="btn-action-currency"
          mat-button
          matSuffix
          mat-icon-button
          matTooltip="Cancelar"
          (click)="node.edit = false; node.valor = node.valorIn"
        >
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </div>
    </div>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="10" (click)="treeControl.expand(node)" style="font-size: 12px">
      <div class="d-flex w-100 align-items-center">
    <button
      mat-icon-button
      matTreeNodeToggle
      [attr.aria-label]="'toggle ' + node.nombre"
    >
      <mat-icon class="mat-icon-rtl-mirror">
        {{ treeControl.isExpanded(node) ? "expand_more" : "chevron_right" }}
      </mat-icon>
    </button>

    <div class="pointer text-left mx-2">{{ node.minipresu }}</div>
    <div class="flex-grow-4"><span class="pointer">{{ node.nombre }}</span></div>
    <div class="text-right mx-2">
      <ng-container *ngIf="!node.edit; else saveValTemplate">
        {{ node.valor | currency }}
        <button
          *ngIf="
            !node.estadoPresupuesto &&
            (node.descompuesto == 'N' || node.descompuesto == 'V') && edit && externalPermission"
          class="btn-action-currency"
          mat-button
          matSuffix
          mat-icon-button
          matTooltip="Editar"
          (click)="node.edit = true">
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>
      <!-- edit -->
      <ng-template #saveValTemplate>
        <mat-form-field appearance="legacy">
          <span matPrefix class="prefix-inp-form">$</span>
          <input matInput  [value]="node.valorIn" appCurrencyDirective (valueChange)="node.valor = $event" required [decimals]="decimals">
        </mat-form-field>
        <button
          class="btn-action-currency"
          mat-button
          *ngIf="node.valor >= 0"
          matSuffix
          mat-icon-button
          matTooltip="Guardar"
          (click)="save(node)">
          <mat-icon>save</mat-icon>
        </button>
        <button
          class="btn-action-currency"
          mat-button
          matSuffix
          mat-icon-button
          matTooltip="Cancelar"
          (click)="node.edit = false; node.valor = node.valorIn">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </div>
    </div>
  </mat-tree-node>
</mat-tree>
