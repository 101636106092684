import {Route, Router, ActivatedRoute, ResolveStart, ActivatedRouteSnapshot} from '@angular/router';
import {EventEmitter, Injectable} from '@angular/core';
import {PATH_HOME} from '../config/constants';
import {NavRoute, RouteData} from '../utils/menu';
import {AdfiService} from '@services/adfi-service';
import {Title} from '@angular/platform-browser';
import {GoogleAnalyticsService} from 'ngx-google-analytics';

@Injectable({
    providedIn: 'root',
})
export class NavRouteService {

    /**
     * Nav route of nav route service
     */
    private navRoute: Route;

    /**
     * Nav routes of nav route service
     */
    private navRoutes: NavRoute[];
    private extraNavRoutes: NavRoute[];

    /**
     * Page back of nav route service
     * @deprecated
     */
    private _pageBack: NavRoute;

    /**
     * Data  of nav route service
     */
    private data: any;

    /**
     * Selected  of nav route service
     */
    public selected: NavRoute;

    /**
     * Breadcrumb list of nav route service
     */
    private _breadcrumbList: Array<any> = [];

    loadedNavs = new EventEmitter();

    /**
     * save path of url
     */
    private path: string;

    /**
     * Creates an instance of nav route service
     */
    constructor(
        private router: Router,
        protected adfiService: AdfiService,
        private titleService: Title,
        private gaService: GoogleAnalyticsService
    ) {
        this.selected = {};
    }

    /**
     * Listens routing
     */
    public listenRouting() {

        this.router.events.subscribe(router => {
            if (router instanceof ResolveStart) {
                this.path = router.url;
                this._breadcrumbList = [];
                let currentUrlPart = (<any> router.state)._root;
                let currUrl = '';
                let needUpdateTilte = false;
                while (currentUrlPart.children.length > 0) {

                    currentUrlPart = currentUrlPart.children[0];
                    const routeSnaphot = <ActivatedRouteSnapshot> currentUrlPart.value;
                    const routeConfig: NavRoute = routeSnaphot.routeConfig;
                    let nav = routeConfig.nav || routeConfig.data;
                    const breadcrumbs = routeConfig.breadcrumbs || [];
                    const length = routeSnaphot.url.length;
                    if (nav && nav.title) {
                        if (nav.title !== 'Listar'){
                            needUpdateTilte = true;
                        }
                        breadcrumbs.unshift(nav.title);
                    }

                    for (let i = 0; i < length; i++) {
                        const path = routeSnaphot.url[i];
                        currUrl += '/' + path;
                        if (currUrl === `/${PATH_HOME}/home`) {
                            continue;
                        }
                        const title = i < breadcrumbs.length ? breadcrumbs[i] : path;
                        this._breadcrumbList.push({
                            title: title != PATH_HOME ? title : null,
                            path: decodeURI(currUrl),
                            icon: nav ? nav.icon : null
                        });
                        nav = null;
                    }
                }
                if (needUpdateTilte){
                    this.updateTitleBrowser();
                }
            }
        });
    }

    /**
     * Determines whether selected is
     * @param navRoute
     * @returns
     */
    public isSelected(navRoute: NavRoute) {
        if (!navRoute) {
            return false;
        }
        if (navRoute.nav && this.selected.nav) {
            return navRoute.nav.title === this.selected.nav.title;
        }
        return false;
    }

    /**
     * Gets page back
     * @deprecated
     */
    public get pageBack() {
        return this._pageBack;
    }

    /**
     * Gets breadcrumb list
     */
    public get breadcrumbList() {
        return this._breadcrumbList;
    }

    /**
     * Homes nav route service
     */
    public home() {
        this.navigate(PATH_HOME);
    }

    /**
     * Loads nav route service
     */
    public async load() {
        this.navRoute = this.router.config.find(route => route.path === PATH_HOME);
        this.navRoutes = (<NavRoute[]> this.navRoute.children).filter(route => this.filter(route))
            .reduce((groupedList: NavRoute[], route: NavRoute) => this.reduce(groupedList, route), []);
        this.extraNavRoutes = await this.adfiService.get<[any]>(`/api/menus`).toPromise();
        this.extraNavRoutes.forEach( (m: any) => {
            let needAdd = true;
            if (m.maybeExist){
                const item = this.navRoutes.find( (f) => f.path === m.maybeExist);
                if (item){
                    item.enabled = m.enabled;
                    m.groupedNavRoutes.forEach( c => {
                        c.customPath = m.path;
                        if (typeof c.index === 'number') {
                            item.groupedNavRoutes.splice(c.index, 0, c);
                        } else {
                            item.groupedNavRoutes.push(c);
                        }
                    });
                    needAdd = false;
                }
            }
            if (needAdd){
                if(typeof m.index === 'number') {
                    this.navRoutes.splice(m.index, 0, m);
                } else {
                    this.navRoutes.push(m);
                }
            }
        });
        this.loadedNavs.emit();
    }

    /**
     * Filters nav route service
     */
    protected filter(route: NavRoute): boolean {
        return (route.nav && route.nav.title !== undefined) && route.enabled !== false;
    }

    /**
     * Reduces nav route service
     * @param groupedList
     * @param route
     * @returns reduce
     */
    protected reduce(groupedList: NavRoute[], route: NavRoute): NavRoute[] {
        if (route.nav.childrens) {
            const childrens = route.nav.childrens.filter(r => this.filterGroup(r, route));
            const vRoute = this.validRole({...route});
            groupedList.push({...vRoute, groupedNavRoutes: childrens.map((c) => this.validRole(c))});
        } else {
            groupedList.push(this.validRole(route));
        }
        return groupedList;
    }

    /**
     * Valids role
     */
    protected validRole(route: NavRoute): NavRoute {

        if (route.path === 'home') {
            route.enabled = true;
            return route;
        }

        let enabled = this.adfiService.validPermission(route.entity, route.permission, route.periodSate);
        if (route.nav && route.nav.childrens) {
            let enabledFather = false;
            for (const children of route.nav.childrens) {
                if (children.nav) {
                    children.enabled = this.adfiService.validPermission(children.entity, children.permission, children.periodSate);
                }
                if (!enabledFather) {
                    enabledFather = children.enabled;
                }
            }
            enabled = enabled || enabledFather;
        }
        route.enabled = enabled;

        return route;
    }

    /**
     * Filters group
     * @param child
     * @param parent
     * @returns true if group
     */
    protected filterGroup(child: NavRoute, parent: NavRoute): boolean {
        return this.filter(child);
    }

    /**
     * Gets nav routes
     * @returns nav routes
     */
    public getNavRoutes(): NavRoute[] {
        return this.navRoutes;
    }

    /**
     * Navs page back
     * @deprecated
     */
    public navPageBack() {
        this.router.navigate([this.pageBack.breadCrumb]);
        this._pageBack = null;
    }

    /**
     * Sets page active
     * @param active
     * @deprecated
     */
    public setPageActive(active: ActivatedRoute) {

        const routes = active.pathFromRoot;

        const paths = [PATH_HOME];
        let parentBack: NavRoute = {nav: {title: 'Inicio'}};

        for (let i = 0; i < routes.length; i++) {
            const route = routes[i];
            const config: NavRoute = route.routeConfig;
            if (!config) {
                continue;
            }
            if (config.nav) {
                paths.push(config.path);
                parentBack = config;
            }
        }

        const r: NavRoute = active.routeConfig || {};
        const nav: RouteData = {title: r.data ? r.data.parentTitle : parentBack.nav.title};
        if (r.data && r.data.parent) {
            paths.push(r.data.parent);
        }
        this._pageBack = {nav, breadCrumb: paths.join('/')};

        setTimeout(() => {
            const subs = this.router.events.subscribe((e) => {
                this._pageBack = null;
                subs.unsubscribe();
            });
        }, 200);
    }

    /**
     * Navigates nav route service
     * @param path
     * @param [config]
     */
    public navigate(path: string, config?: { data: any }) {
        if (config) {
            this.data = config.data;
        }
        if (path.startsWith('./')) {
            this.router.navigate([decodeURI(this.router.url), path.split('./', 2)[1]]);
        } else {
            this.router.navigate([`${PATH_HOME}/${path}`]);
        }
    }

    /**
     * Gets data navigate
     * @template I
     * @returns data navigate
     */
    public getDataNavigate<I>(): I {
        const data = this.data || {};
        this.data = null;
        return data;
    }

    public modifyBreadcrumb(index, path, searchInChildren = false) {
        let icon;
        let title;
        if (this.extraNavRoutes) {
            for (let i = 0; i < this.extraNavRoutes.length && !title; i++) {
                const m = this.extraNavRoutes[i];
                if (searchInChildren && m.groupedNavRoutes){
                    for (let j = 0; j < m.groupedNavRoutes.length && !title; j++) {
                        if (m.groupedNavRoutes[j].path && m.groupedNavRoutes[j].path == decodeURIComponent(path)){
                            title = m.groupedNavRoutes[j].nav.title;
                            icon = m.groupedNavRoutes[j].nav.icon;
                        } else if (Array.isArray(m.groupedNavRoutes[j])) {
                            for (let k = 0; k < m.groupedNavRoutes[j].length && !title; k++){
                                if (m.groupedNavRoutes[j][k].path && m.groupedNavRoutes[j][k].path == decodeURIComponent(path)) {
                                    title = m.groupedNavRoutes[j][k].nav.title;
                                    icon = m.groupedNavRoutes[j][k].nav.icon;
                                }
                            }
                        }
                    }
                }
                if (!title && m.path && m.path == path){
                    title = m.nav.title;
                    icon = m.nav.icon;
                }
            }
        }
        if (this._breadcrumbList && index > 1 && this._breadcrumbList[index]){
            this._breadcrumbList[index].icon = icon;
            this._breadcrumbList[index].title = title;
        }
    }

    public updateTitleBrowser(){
        let text = '';
        for (const breadcrumb of this._breadcrumbList) {
            if (breadcrumb.title && breadcrumb.title !== 'Listar'){
                text += ' ' + breadcrumb.title;
            }
        }
        if (text) {
            this.titleService.setTitle(`ADFI |${text}`);
        } else {
            this.titleService.setTitle(`ADFI`);
        }
        this.gaService.pageView(this.path, text ? text : 'Inicio', undefined, {
            user_id: this.adfiService.user.id
        });
    }
}
