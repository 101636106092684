import {ApolloError} from '@apollo/client/core';
import {Component, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {AdfiService} from '@services/adfi-service';
import {DocumentMovComponent} from '@pages/adjustment/document-mov/document-mov.component';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AccountsService} from '@services/accounts.service';
import {MovementComponent} from '@pages/adjustment/movement/movement.component';
import {LoadingService} from '@services/loading.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {Entities, ENUM_ACTIONS, PeriodState, Permission} from '@Interfaces/index';

import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ListComponent} from '@Components/ui/list/list.component';
import {AdfiUtil} from '@Components/util/adfi-util';
import {Router} from '@angular/router';
import { FormComponent } from '@Components/ui/form/form.component';

export const keyCache = (service: AdfiService) => {
  return service.user.id + '_ADJ_BAL_' + service.user.centroCosto._id;
};
@Component({
  selector: 'app-adjust',
  templateUrl: './adjust.component.html',
  styleUrls: ['./adjust.component.css']
})
export class AdjustComponent implements OnInit {

  @ViewChild('movements') movementTable: ListComponent;
  canOpen: boolean;
  canClose: boolean;
  periodOpen: boolean;
  canDelete: boolean;
  canCancel: boolean;

  constructor(
      protected documentService: AccountsService,
      protected adfiGrowlService: AdfiGrowlService,
      public loading: LoadingService,
      protected matDialog: MatDialog,
      protected adfiService: AdfiService,
      protected adfiGraphql: AdfiGraphqlService,
      protected router: Router
  ) {
  }

  public documentComp: any;
  public adjustments: any[];
  public accountingDocuments: any[];
  totals: { debit: number, credit: number };

  get getModule() {
    return 'AJ';
  }

  get filterByDocuments() {
    return `cencos: "${this.adfiService.user.centroCosto.id}", module: "${this.getModule}", order: {  numberDoc: "ASC"}`;
  }

  ngOnInit() {
    this.canOpen = this.adfiService.validPermission(Entities.DOCUMENT, Permission.OPEN);
    this.canClose = this.adfiService.validPermission(Entities.DOCUMENT, Permission.CLOSE);
    this.canDelete = this.adfiService.validPermission(Entities.DOCUMENT, Permission.DELETE);
    this.canCancel = this.adfiService.validPermission(Entities.DOCUMENT, 'CANCEL_ADJUSTMENT');
    this.periodOpen = this.adfiService.getUserPeriod().estado === PeriodState.OPEN;
    this.totals = { debit: 0, credit: 0 };
    if (this.getModule === 'AJ') {
      this.reloadDocuments();
    }
  }

  private async loadDocuments(next, error, filter) {
    this.loading.show();
    const count = await this.adfiGraphql.countEntity('documents', filter);
    this.adfiGraphql.getEntity('documents',
        'numberDoc proof {id _id name} type {id _id name} date cencos {id _id deDescripcion} numDay period state module reason difference',
        count, filter, {next, error});
  }

  afterCreateDocument(created) {
    this.loading.show();
    this.adfiGrowlService.success('Cargando', 'Recargando listado de documentos');
    const ok = (docs: any[]) => {
      this.loading.hide();
      this.accountingDocuments = docs;
      this.adfiGrowlService.success('Completado', 'Creación de documento n°' + created.numberDoc + ' éxitosa');
      for (const d of docs) {
        if (d.numberDoc === created.numberDoc && d.proof.id === created.proof.id && d.type.id === created.type.id) {
          this.documentComp = d;
          this.totals.debit = 0;
          this.totals.credit = 0;
          this.adjustments = undefined;
          break;
        }
      }
    };
    const err = (er) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(er, 'Error al cargar los documentos contables');
    };
    this.loadDocuments(ok, err, this.filterByDocuments);
  }

  public newDocument() {
    const dialog = this.matDialog.open(DocumentMovComponent);
    dialog.componentInstance.tittle = 'Nuevo Documento Contable';
    const subs = dialog.componentInstance.afterSave;
    subs.subscribe((e: any) => {
      if (e) {
        this.loading.show();
        const next = (created) => {
          dialog.close();
          this.afterCreateDocument(created);
        };
        const error = (err) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(err, 'Error en la creación del documento contable');
        };
        this.adfiGraphql.createEntity('Document',
        {
                  reason: e.reason,
                  date: e.date.toUTCString(),
                  proof: e.proof.id,
                  type: e.type.id,
                  cencos: this.adfiService.user.centroCosto.id,
                  module: this.getModule
                }, 'numberDoc proof {id} type {id}', {next, error} );
      }
    });
  }

  public newMovement() {
    const dialog = this.matDialog.open(MovementComponent, {
      data:  { document: this.documentComp }
    });
    dialog.componentInstance.tittle = 'Nuevo Movimiento';
    const subs = dialog.componentInstance.afterSave;
    subs.subscribe((e: any) => {
      if (e) {
        this.loading.show();
        const next = () => {
          this.loading.hide();
          this.updateValuesDocument();
          this.adfiGrowlService.info('Finalizado', 'Movimiento agregado correctamente');
          // fire action after save to clean fields
          dialog.componentInstance.onSave = true;
          this.selectDocument(this.documentComp);
        };
        const error = (err: ApolloError) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(err);
        };
        this.adfiGraphql.createEntity('Adjustment',
            {
              proof: this.documentComp.proof._id,
              type: this.documentComp.type._id,
              description: e.description,
              date: this.documentComp.date,
              nature: e.nature,
              third: e.third.id,
              numDoc: this.documentComp.numberDoc,
              numDay: this.documentComp.numDay,
              numPeriod: this.documentComp.period,
              value: e.value,
              account: e.account.id,
              cencos: this.documentComp.cencos._id,
              module: this.documentComp.module
          }, undefined, {next, error});
      }
    });
  }

  postLoadAdjustments() {
    const next = (d) => {
      this.totals.debit = d.total ? d.total : 0;
      this.totals.credit = d.total2 ? d.total2 : 0;
      if (this.documentComp.difference === 0 && this.totals.debit !== this.totals.credit) {
        this.updateValuesDocument();
      }
    };
    const error = (e) => {
      this.adfiGrowlService.errorMessage(e);
    };
    this.adfiGraphql.createEntity('Summation', {
      nameClass: 'Adjustments\\Adjustment',
      filters: JSON.stringify(this.movementTable.getAllFilters())
    }, 'total total2', {next, error});
  }

  selectDocument(value: any) {
    this.documentComp = value;
    this.totals.debit = 0;
    this.totals.credit = 0;
    if (this.movementTable) {
      this.movementTable.reload();
    }
  }

  showTrialBalance() {
    if (this.documentComp.state !== 'C'){
      AdfiUtil.saveInStorage(keyCache(this.adfiService), this.getModule);
    }
    window.open(window.location.href.replace(this.router.url, '') + this.router.createUrlTree(['nav/contabilidad/balance-comprobacion']), '_blank');
  }

  editMovement(adj: any) {
    const dialog = this.matDialog.open(MovementComponent, {
      data: { document: this.documentComp, adjustment: adj}
    });
    dialog.componentInstance.tittle = 'Editar Movimiento';
    const subs = dialog.componentInstance.afterSave.subscribe((newAdj: any) => {
      this.loading.show();
      subs.unsubscribe();
      const next = () => {
        dialog.close();
        this.loading.hide();
        this.selectDocument(this.documentComp);
        this.updateValuesDocument();
        this.adfiGrowlService.success('Proceso terminado', 'Actualizado correctamente');
      };
      const error = (e) => {
        this.loading.hide();
        this.adfiGrowlService.errorMessage(e, 'Error al actualizar el movimiento');
      };
      this.adfiGraphql.updateEntity('Adjustment',
          {
            id: newAdj.id,
            proof: this.documentComp.proof._id,
            type: this.documentComp.type._id,
            description: newAdj.description,
            date: this.documentComp.date,
            nature: newAdj.nature,
            third: newAdj.third.id,
            numDoc: this.documentComp.numberDoc,
            numDay: this.documentComp.numDay,
            numPeriod: this.documentComp.period,
            value: newAdj.value,
            account: newAdj.account.id,
          }, undefined, {next, error});
    });
  }

  private updateValuesDocument() {
    this.loading.show();
    const next = (res) => {
      if (res) {
        this.documentComp.difference = res.difference;
      }
      this.loading.hide();
    };
    const error = (e) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(e);
    };
    this.adfiGraphql.updateEntity('Document', {
      id: this.documentComp.id,
      difference: Math.trunc(Math.random() * 10000) / 10000
    }, 'difference', {next, error});
  }

  editDocument() {
    const dialog = this.matDialog.open(DocumentMovComponent, { data: this.documentComp });
    dialog.componentInstance.tittle = 'Editar Documento Contable';
    const subs = dialog.componentInstance.afterSave.subscribe((newDoc: any) => {
      this.loading.show();
      subs.unsubscribe();
      const next = () => {
        dialog.close();
        const okd = (docs: any[]) => {
          this.loading.hide();
          this.accountingDocuments = docs;
          this.adfiGrowlService.success('Completado', 'Edición de documento éxitosa');
          for (const d of docs) {
            if (d.id === newDoc.id) {
              this.documentComp = d;
              this.selectDocument(this.documentComp);
              break;
            }
          }
          subs.unsubscribe();
        };
        const errd = (er) => {
          this.loading.hide();
          subs.unsubscribe();
          this.adfiGrowlService.errorMessage(er, 'Error al cargar los documentos contables');
        };
        this.adfiGrowlService.success('Proceso terminado', 'Actualizado correctamente, cargando cambios');
        this.loadDocuments(okd, errd, this.filterByDocuments);
      };
      const error = (e) => {
        this.loading.hide();
        this.adfiGrowlService.errorMessage(e, 'Error al actualizar el documento');
      };
      this.adfiGraphql.updateEntity('Document',
          {
                    id: newDoc.id,
                    reason: newDoc.reason,
                    date: typeof newDoc.date !== 'string' ? newDoc.date.toUTCString() : newDoc.date,
                    proof: newDoc.proof.id,
                    type: newDoc.type.id
                }, null, {next, error});
    });
  }

  removeMovement(adj: any) {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Eliminar',
        content: '¿Esta seguro de querer eliminar el movimiento?',
        action: ENUM_ACTIONS.DELETE
      } as ConfirmDialogData
    });
    const subs = dialog.afterClosed().subscribe((val) => {
      if (val) {
        this.loading.show();
        const next = () => {
          dialog.close();
          this.adfiGrowlService.success('Proceso terminado', 'Eliminado correctamente');
          this.loading.hide();
          this.selectDocument(this.documentComp);
          this.updateValuesDocument();
        };
        const error = (e) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(e, 'Error al eliminar el movimiento');
        };
        this.adfiGraphql.deleteEntity('Adjustment', adj.id, {next, error});
      }
      subs.unsubscribe();
    });
  }

  removeDocument() {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: '¿Esta seguro de querer eliminar el documento?',
        content: 'Esto eliminirá tambien todos los movimientos asociados a este',
        action: ENUM_ACTIONS.DELETE
      } as ConfirmDialogData
    });
    const subs = dialog.afterClosed().subscribe((val) => {
      if (val) {
        this.loading.show();
        const next = () => {
          dialog.close();
          this.adfiGrowlService.success('Proceso terminado', 'Eliminado correctamente');
          this.loading.hide();
          this.documentComp = undefined;
          this.adjustments = undefined;
          this.ngOnInit();
        };
        const error = (e) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(e, 'Error al eliminar el documento');
        };
        this.adfiGraphql.deleteEntity('Document', this.documentComp.id, {next, error});
      }
      subs.unsubscribe();
    });
  }

  closeDocument(state: string) {
    const dialog = this.matDialog.open(ConfirmDialogComponent, {
      data: {
        title: `¿Esta seguro de querer ${ state === 'C' ? 'cerrar' : 'abrir'} este documento?`,
        content:  state === 'C' ? 'Recuerde que esto evitará que pueda modificar cualquier movimiento asociado.' : 'Puede generarse errores en caso de que ya este fuera de las fechas permitidas'
      } as ConfirmDialogData
    });
    const subs = dialog.afterClosed().subscribe((val) => {
      if (val) {
        this.loading.show();
        subs.unsubscribe();
        const ok = () => {
          dialog.close();
          this.loading.hide();
          this.adfiGrowlService.success('Proceso terminado', 'Cierre éxitoso, cargando cambios');
          this.reloadDocuments();
        };
        const err = (e) => {
          this.loading.hide();
          this.adfiGrowlService.errorMessage(e, 'Error en el cierre del documento contable');
        };
        this.adfiService.get(`/api/adfib/${state === 'C' ? 'close' : 'open'}/document/` + this.documentComp._id).subscribe(ok, err);
      }
    });
  }

  cancelDocument() {
    const dialog = this.matDialog.open(FormComponent, {
          data: {
              entityName: 'CancelItem',
              customTitle: 'Anular Comprobante Contable',
              customActionBtnText: 'Anular',
              customData: {
                'idCancellation': this.documentComp._id,
                'type': 'CANCEL_ADJUSTMENT',
              },
              action: ENUM_ACTIONS.CREATE
          },
          minWidth: '50%'
      });
      dialog.afterClosed().subscribe(data => {
        this.reloadDocuments();
      })
  }

  reloadDocuments() {
    this.loading.show();
    const okd = (docs: any[]) => {
      this.loading.hide();
      this.accountingDocuments = docs;
      if (this.documentComp){
        for (const d of docs) {
          if (d.id === this.documentComp.id) {
            this.documentComp = d;
            this.selectDocument(this.documentComp);
            break;
          }
        }
      }
    };
    const errd = (er) => {
      this.loading.hide();
      this.adfiGrowlService.errorMessage(er, 'Error al recargar documentos contables');
    };
    this.loadDocuments(okd, errd, this.filterByDocuments);
  }
 
}
