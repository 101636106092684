import { Component, OnInit } from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {Attribute, ColumnGroup, Type} from '@Components/table/table.component';
import {Entities, PeriodState, Permission} from '@Interfaces/index';

@Component({
  selector: 'app-transactions-of-third',
  templateUrl: './transactions-of-third.component.html',
  styleUrls: ['./transactions-of-third.component.scss']
})
export class TransactionsOfThirdComponent implements OnInit {
    permissionViewAll = false;
    generateMassive = false;

    columnsHeaderGroupAllConsolidate: ColumnGroup[] = [
        {
            column: 'ID_CC',
            name: 'Centro de Costo',
            type: Type.Number
        },
        {
            column: 'TOWN',
            name: '',
            hideGroupName: true,
            type: Type.String
        },
        {
            column: 'DE_DESCRIPCION',
            name: '',
            hideGroupName: true,
            type: Type.String
        },
    ];
    columns = [
        {
            column: 'V_MEDIOPAGO',
            name: 'Medio de Pago Recaudo',
            group: 'N_ID',
            type: Type.Option,
            attr: { classCell: 'text-center', options: {CN: 'Consignación', CH: 'Cheque', ND: 'Nota débito', TR: 'Transferencia', PL: 'Pago en línea'} } as Attribute
        },
        {
            column: 'DB_NUMERRECIB',
            name: 'Transacción Número Recaudo',
            group: 'N_ID',
            type: Type.String,
            attr: null
        },
        {
            column: 'V_OBJETO',
            name: 'Descripción Recaudo',
            group: 'N_ID',
            type: Type.String,
            attr: null
        },
        {
            column: 'THIRD_ING',
            name: 'Tercero Recaudo',
            group: 'N_ID',
            type: Type.String,
            attr: null
        },
        {
            column: 'N_VALOR',
            name: 'Valor Recaudo',
            group: 'N_ID',
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        },
        {
            column: 'N_SALDO',
            name: 'Saldo Recaudo',
            group: 'N_ID',
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        },
        {
            column: 'ACCOUNT',
            name: 'Cuenta Recaudo',
            group: 'N_ID',
            type: Type.String,
            attr: null
        },
        {
            column: 'D_CREACION',
            name: 'Fecha Recaudo',
            group: 'N_ID',
            type: Type.Date,
            attr: { classCell: 'text-right'} as Attribute
        },

        {
            column: 'V_MEDIOPAGO_EGRE',
            name: 'Medio de Pago Giro',
            type: Type.Option,
            attr: { classCell: 'text-center', options: {CN: 'Consignación', CH: 'Cheque', ND: 'Nota débito', TR: 'Transferencia', PL: 'Pago en línea'}} as Attribute
        },
        {
            column: 'DB_NUMERRECIB_EGRE',
            name: 'Transacción Número Giro',
            type: Type.String,
            attr: null
        },
        {
            column: 'V_OBJETO_EGRE',
            name: 'Descripción Giro',
            type: Type.String,
            attr: null
        },
        {
            column: 'THIRD_EGRE',
            name: 'Tercero Giro',
            group: 'ID',
            type: Type.String,
            attr: null
        },
        {
            column: 'N_VALOR_EGRE',
            name: 'Valor Giro',
            group: 'N_ID',
            type: Type.Currency,
            attr: { classCell: 'text-right'} as Attribute
        },
        {
            column: 'ACCOUNT_EGRE',
            name: 'Cuenta Giro',
            type: Type.String,
            attr: null
        },
        {
            column: 'D_CREACION_EGRE',
            name: 'Fecha Giro',
            type: Type.Date,
            attr: { classCell: 'text-right'} as Attribute
        },
    ];
    data: any;

  constructor(private service: AdfiService, private loading: LoadingService, private adfiGrowlService: AdfiGrowlService) { }

  ngOnInit(): void {
      this.permissionViewAll = this.service.validPermission(Entities.TE_MOV_EGRE, Permission.VIEW_ALL, PeriodState.NO_STATUS);
      this.loadData();
  }

  loadData(changeState?: boolean) {
      if (changeState !== undefined) {
          this.generateMassive = changeState;
      }
      const next = (d) => {
          if (d && d.fileName) {
              this.data = JSON.parse(d.fileName);
              this.loading.hide();
          } else {
              this.adfiGrowlService.error('Error',
                  'Se ha presentado un error al generar la consulta, por favor contacte a soporte técnico');
              this.loading.hide();
          }
      };
      this.service.getJson('ReportTeMovEgre', JSON.stringify({massive: this.generateMassive, type: 'json'}), 'fileName', next);
  }

    export(type= 'xlsx') {
        this.service.download('ReportTeMovEgre',  JSON.stringify({massive: this.generateMassive, type}),
            'fileName', 'application/' + type, ('Operaciones_a_favor_terceros_' +
            (this.generateMassive ? 'consolidado' : this.service.user.centroCosto.deDescripcion.replace(/\s/g, '_') )).toUpperCase() + '.' + type);
    }
}
