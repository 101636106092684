<mat-card>
    <mat-card-header  class="full-width">
        <mat-card-title class="d-flex justify-content-between full-width ">
            <section>
                Presupuesto Inicial
                <button class="ml-4" mat-raised-button color="primary" (click)="openVideoPresupuesto()">Ver video</button>
            </section>
            <mat-chip-list>
                <mat-chip *ngIf="presupuestoInicial && counter.counterEnd > 0 && (!presupuestoInicial?.estado || presupuestoInicial?.estado!='C')"
                          matTooltip="Plazo para el registro de presupuesto inicial"
                          selectable="false" [ngClass]="getColorTime()">
                    {{ counter.counterEnd | formatTime }}
                </mat-chip>
                <mat-chip *ngIf="counter.counterEnd < 0"
                        selectable="false" class="green">Presupuesto inicial habilitado en modo solo lectura
                </mat-chip>
                <mat-chip *ngIf="counter.counterStart > 0"
                          selectable="false" selected color="primary">El Presupuesto Inicial se habilitará en {{ counter.counterStart | formatTime }}
                </mat-chip>
            </mat-chip-list>
        </mat-card-title>

    </mat-card-header>

    <mat-card-content>
        <div class="row ml-1">
            <div class="col-4">
                <h4 style="margin-block-end: 0">Fuente financiación</h4>
            </div>
        </div>
        <div class="row mt-1 ml-1" *ngIf="fuentes">
            <div class="col-4">
                <mat-form-field class="list-fuente full-width">
                    <mat-label> Seleccionar: </mat-label>
                    <mat-select placeholder="Seleccionar" (selectionChange)="selectPrFuente($event.value)">
                        <mat-option *ngFor="let item of fuentes" [value]="item._id">
                            {{ item._id }} - {{ item.prfinVNombre }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- col -->
            <div class="col-auto" *ngIf="counter.counterEnd > 0">
                <button mat-raised-button color="primary" (click)="viewVerificacion()">Verificación</button>
            </div>
            <!-- col -->
            <div class="col-auto" *ngIf="aprobar && counter.counterEnd > 0">
                <button mat-raised-button color="primary" (click)="viewPrInicial()">Solicitar aprobación</button>
            </div>
            <div class="col-auto" *ngIf="presupuestoInicial?.estado!='I'">
                <button mat-raised-button color="primary" (click)="getObservations()">Observaciones</button>
            </div>
            <!-- col admin-->
            <div class="col-auto" *ngIf="counter.counterEnd > 0 && isEditable() && (presupuestoInicial?.estado=='A' || presupuestoInicial?.estado=='C')">
                <button mat-raised-button color="primary" (click)="estadoAPendiente()" matTooltip="Cambiar el estado a Pendiente">
                    <mat-icon>autorenew</mat-icon>
                    Estado a pendiente
                </button>
            </div>
            <!-- col actor-->
            <div class="col-auto">
                <button mat-raised-button color="primary" *ngIf="archivo && counter.counterEnd > 0"
                    (click)="archivoAcuerdo()">
                    Archivo Acuerdo
                </button>
            </div>
            <div class="col-auto">
                <button mat-raised-button *ngIf="presupuestoInicial?.archivo && (presupuestoInicial.estado=='C' || presupuestoInicial.estado=='X')" color="primary" (click)="descargarArchivo()">
                    Descargar Archivo
                    <mat-icon>arrow_downward</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="presupuestoInicial">
                <div class="col-auto" *ngIf="counter.counterEnd > 0 && presupuestoInicial.estado && presupuestoInicial.estado!='C' && presupuestoInicial.estado !== 'I'">
                    <button class="btn-action-currency" color="primary" mat-button matSuffix mat-icon-button
                        (click)="print()" matTooltip="Imprimir presupuesto Inicial"
                        [disabled]="!presupuestoInicial.id">
                        <mat-icon>print</mat-icon>
                    </button>
                </div>
                <!-- col -->
                <div class="col-auto d-flex align-items-center">
                    <h2 class="d-inline pr-1" style="margin-block-start: 0">Estado: </h2>
                    <mat-chip-list style="margin-bottom: 0.83em;">
                        <mat-chip [attr.selected]="presupuestoInicial.estado=='C'">{{ estado }}</mat-chip>
                    </mat-chip-list>
                </div>
            </ng-container>
        </div>

        <div class="row">
            <div class="col-sm">
                <app-tree-presupuesto [tipoPresupuesto]="tipo.INGRESO" (changeItem)="reloadBudget($event)" [externalPermission]="counter.counterEnd > 0"></app-tree-presupuesto>
            </div>
            <div class="col-sm">
                <app-tree-presupuesto [tipoPresupuesto]="tipo.EGRESO" (changeItem)="reloadBudget($event)" [externalPermission]="counter.counterEnd > 0"></app-tree-presupuesto>
            </div>
        </div>
    </mat-card-content>

</mat-card>
