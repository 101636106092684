<app-list #table entityName="PresupuestoInicial" module="Presupuesto">
    <div class="footer">
        <button mat-raised-button color="primary" (click)="download()" [disabled]="table.loadingData">Descargar Todos</button>
    </div>
</app-list>
<div class="over-message" *ngIf="downloadedFiles">
    Archivos descargados {{downloadedFiles}} - {{total}}
</div>
<div class="over-message" *ngIf="generatingZip">
    Comprimiendo archivos {{percentageGenZip}}%
</div>
