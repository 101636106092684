import {Component, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {DataEntity, Entities, ENUM_ACTIONS, Permission, TICK, TipoPresupuesto} from '@Interfaces/index';
import {TreePresupuestoComponent} from '@Components/presupuesto/tree-presupuesto/tree-presupuesto.component';
import {ViewPresupuestoInicialComponent} from '@Components/presupuesto/view-presupuesto-inicial/view-presupuesto-inicial.component';
import {AdfiService} from '@services/adfi-service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {LoadingService} from '@services/loading.service';
import {FormComponent} from '@Components/ui/form/form.component';
import {AdfiRest} from '@services/adfi-rest';
import {Subscription, timer} from 'rxjs';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {AdfiUtil} from '@Components/util/adfi-util';
import { UIHelper } from '@Components/util/UIHelper';
import { DisplayItemComponent } from '@Components/ui/display-item/display-item.component';
import {Pages} from '@Interfaces/index';

@Component({
    selector: 'app-prmini-presupuesto',
    templateUrl: './prmini-presupuesto.component.html',
    styleUrls: ['./prmini-presupuesto.component.css']
})
export class PrminiPresupuestoComponent implements OnInit, OnDestroy {

    /**
     * Fuentes  of prmini presupuesto component
     */
    public fuentes: any[];

    /**
     * Presupuesto inicial of prmini presupuesto component
     */
    public presupuestoInicial: any;

    /**
     * Max height of prmini presupuesto component
     */
    public readonly maxHeight: number;

    /**
     * View child of prmini presupuesto component
     */
    @ViewChildren(TreePresupuestoComponent) presupuestos: QueryList<TreePresupuestoComponent>;

    public archivo: boolean;

    /**
     * Estado  of prmini presupuesto component
     */
    public estado: string;

    /**
     * Aprobar  of prmini presupuesto component
     */
    public aprobar: boolean;

    /**
     * Determines whether select pr fuente on
     */
    private onSelectPrFuente: boolean;

    counter: {
        counterStart: number;
        counterEnd: number;
        countDown: Subscription | null;
        countStart: Subscription | null;
    } = {
        counterStart: 1,
        counterEnd: 0,
        countDown: null,
        countStart: null,
    };
  period: number;

    constructor(
        public sLoading: LoadingService,
        private matDialog: MatDialog,
        private adfiGrowlService: AdfiGrowlService,
        private adfiService: AdfiService,
        private graphService: AdfiGraphqlService) {
        this.maxHeight = window.innerHeight - 240;
    }

    /**
     * Gets tipo
     */
    public get tipo() {
        return TipoPresupuesto;
    }

    /**
     * on init
     */
    ngOnInit() {
        this.reloadData();
    }

    reloadData() {
        this.sLoading.show();
        const next = (d) => {
            if (Array.isArray(d)) {
                this.fuentes = d;
            } else {
                this.sLoading.hide();
                this.presupuestoInicial = d ? d : {};
                this.updatePresupuesto();
            }
        };
        const error = (e) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphService.getEntity('prFuentesFinans', 'prfinVNombre', 5, null, {next, error});
        this.loadMiniPresu(next, error);
        this.getTime();
    }

    reloadBudget(reload: boolean) {
        const next = (d) => {
                this.presupuestoInicial = d ? d : {};
                this.updateObjectPresupuesto(this.presupuestoInicial);
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.loadMiniPresu(next, error);
    }
  getTime() {
    const next = (d) => {
      this.period = d.period;
      this.counter.counterStart = this.adfiService.getCountDown(d.startDateRegister);
      if (this.counter.counterStart > 0) {
          this.counter.countStart = timer(0, TICK).subscribe(() => {
              --this.counter.counterStart;
              if (this.counter.counterStart <= 0) {
                  this.counter.countStart.unsubscribe();
                  this.endTime(d);
              }
          });
      } else {
          this.endTime(d);
          if (this.counter.counterEnd <= 0) {
              const valid = (dates) => {
                  if (dates.length) {
                      const param = JSON.parse(dates[0]);
                      const end = new Date(param.endDate);
                      this.counter.counterEnd = (end.getTime() / 1000) - ((new Date()).getTime() / 1000);
                      if (this.counter.counterEnd > 0) {
                          this.counter.countDown = timer(0, TICK).subscribe(() => --this.counter.counterEnd);
                      }
                  }
              };
              this.graphService.loadAlerts('ExeptionAvalibleDate', 'getExceptionRegisterFor', `"3", "InitialBudget", "${this.adfiService.user.centroCosto._id}", "${new Date().getMonth() + 1}"`, valid);
          }
      }
    };
    const error = (e) => {
      this.sLoading.hide();
      this.adfiGrowlService.errorMessage(e);
    };
    this.adfiService.getTimeControl('InitialBudget', next, error);
  }

    getColorTime() {
        const days = (this.counter.counterEnd / 86400);
        if (days < 7) {
            return 'red';
        } else if (days < 15) {
            return 'orange';
        }
        return 'green';
    }

    loadMiniPresu(next: (d) => void, error: (e) => void) {
        this.graphService.getFirstEntity('presupuestoInicials',
            'fechaAcuerdo numeroAcuerdo estado archivo fileName',
            `centroCosto_id: ${this.adfiService.user.centroCosto._id}`, {next, error});
    }


    updateObjectPresupuesto(val?: any) {
        if (val) {
            this.presupuestoInicial = Object.assign(this.presupuestoInicial, val);
        }

        if (!this.presupuestoInicial) {
            return;
        }

        this.archivo = this.presupuestoInicial.estado === 'A'
            && this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.DOWNLOAD);

        switch (this.presupuestoInicial.estado) {
            case 'A':
                this.estado = 'Aprobado';
                break;
            case 'P':
                this.estado = 'Pendiente aprobación';
                break;
            case 'C':
                this.estado = 'Cargado';
                break;
            case 'X':
                this.estado = 'Cerrado';
                break;
            default:
                this.estado = 'Inicial';
                break;
        }

        this.aprobar = this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.EDIT) &&
            !this.adfiService.validPermission(Entities.INITIAL_BUDGET, Permission.APPROVE);
        if (this.aprobar) {
            this.aprobar = !this.presupuestoInicial.id || ['I', 'P'].indexOf(this.presupuestoInicial.estado) >= 0;
        }
    }

    private updatePresupuesto(val?: any) {
        this.updateObjectPresupuesto(val);
        if (this.onSelectPrFuente) {
            for (const c of this.presupuestos.toArray()) {
                c.update();
            }
        }
    }

    public openVideoPresupuesto(){
        const URL = '/nav/help/' + Pages.VIDEOS + '?video=PI';
        window.open(URL);
    }

    /**
     * Views verificacion
     */
    public viewVerificacion() {
        const minWidth = window.innerWidth > 1000 ? window.innerWidth / 2 : '90%';
        const maxHeight = window.innerHeight - 100;
        const dialog = this.matDialog.open(ViewPresupuestoInicialComponent, {data: this.presupuestoInicial, minWidth, maxHeight});
        const subsSave = dialog.componentInstance.saved.subscribe((pi: any) => {
            this.updatePresupuesto(pi);
        });
        const subsForm = dialog.afterClosed().subscribe(() => {
            subsForm.unsubscribe();
            subsSave.unsubscribe();
        });
    }

    /**
     * Views presupuesto
     */
    public viewPrInicial() {
        const minWidth = window.innerWidth > 1000 ? window.innerWidth / 2 : '90%';
        const dialog = this.matDialog.open(FormComponent,
            {
                data: {
                    module: 'Presupuesto',
                    entityName: 'PresupuestoInicial',
                    action: ENUM_ACTIONS.EDIT,
                    item: this.presupuestoInicial.id,
                    returnDataAfterSave: true
                } as DataEntity,
                minWidth, maxHeight: '70vh'
            });
        const subsSave = dialog.componentInstance.dialogRef.afterClosed().subscribe((data) => {
            if (data) {
                const next = (mini) => {
                    this.updatePresupuesto(mini);
                };
                const error = (e) => {
                    this.adfiGrowlService.errorMessage(e);
                };
                this.loadMiniPresu(next, error);
                if (data.consejoDirectivo && data.consejoDirectivo.length) {
                    let index = 1;
                    for (const d of data.consejoDirectivo) {
                       this.graphService.updateEntity('ConsejoDirectivo', {
                           id: d,
                           orderAdded: index
                       }, undefined, {next: () => {}, error: () => {}});
                       index++;
                    }
                }
            }
            subsSave.unsubscribe();
        });
    }

    public selectPrFuente(id: number) {
        for (const c of this.presupuestos.toArray()) {
            c.selectPrFuente(id);
        }
        this.onSelectPrFuente = true;
    }

    public descargarArchivo() {
        AdfiUtil.downloadMediaObject(this.presupuestoInicial.archivo, { fieldName: '', options: {bucket: 'files_initial_budget'}},
            this.period);
    }

    public archivoAcuerdo() {
        const dialog = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'PresupuestoInicial',
                module: 'Presupuesto',
                group: 'uploadPresu',
                item: this.presupuestoInicial.id,
                customTitle: 'Subir Presupuesto Inicial',
                customActionBtnText: 'Subir',
                action: ENUM_ACTIONS.EDIT,
            } as DataEntity,
            minWidth: '50%'
        });
        const subs = dialog.afterClosed().subscribe((event) => {
            this.reloadData();
            this.sLoading.hide();
        });
    }

    /**
     * estadoAPendiente
     */
    public estadoAPendiente() {
        const message = this.presupuestoInicial.archivo
            ? `Se borrará el archivo cargado y el estado se actualizará a "Pendiente"`
            : 'Se actualizará el estado a "Pendiente"';
        const dialog = this.matDialog.open(ConfirmDialogComponent,
            {data: {title: 'Seguro que desea continuar?', content: message, action: ENUM_ACTIONS.EDIT} as ConfirmDialogData});

        const callConfirm = (val) => {
            subs.unsubscribe();
            if (!val) {
                return;
            }
            this.cambioEstadoAPendiente();
        };
        const subs = dialog.afterClosed().subscribe(callConfirm);
    }

    /**
     * Cambios estado apendiente
     */
    private cambioEstadoAPendiente() {
        this.sLoading.show();
        const callCambioEstado = (updatedEntity) => {
            this.sLoading.hide();
            this.adfiGrowlService.success('Cambio estado', 'Actualizado correctamente a Pendiente');
            this.updatePresupuesto(updatedEntity);
        };
        const callCambioEstadoError = (error) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(error);
        };
        const form = {id: this.presupuestoInicial.id, estado: 'I'};
        this.graphService.updateEntity('PresupuestoInicial', form,
            'fechaAcuerdo numeroAcuerdo estado archivo fileName',
            {next: callCambioEstado, error: callCambioEstadoError});
    }

    print() {
        this.sLoading.show();
        this.adfiService.get(`/api/adfib/report?report=PRESUPUESTO_INICIAL`, undefined,
            'arraybuffer').subscribe((data) => {
            AdfiService.downLoadFile(data, 'application/pdf', 'report.pdf');
            this.sLoading.hide();
        }, () => {
            this.sLoading.hide();
            this.adfiGrowlService.error('Reporte de presupuesto inicial',
                ' Se ha presentado un error al generar el reporte, por favor contacta a soporte técnico'
            );
        });
    }

    isEditable() {
        return this.adfiService.hasRole(182);
    }

    ngOnDestroy() {
        if (this.counter.countStart) {
            this.counter.countStart.unsubscribe();
        }
        if (this.counter.countDown) {
            this.counter.countDown.unsubscribe();
        }
    }

    private endTime(period: any) {
        this.counter.counterEnd = this.adfiService.getCountDown(
            period.endDateRegister
        );
        this.counter.countDown = timer(0, TICK).subscribe(
            () => --this.counter.counterEnd
        );
        if (this.counter.counterEnd <= 0) {
            this.counter.countDown.unsubscribe();
        }
    }

    getObservations() {
        const next = (d) => {
            if (d!== undefined) {
                const mostRecentHistory = d.validationFormHistories.edges.reduce(
                    (mostRecent, current) => {
                      const currentDate = Date.parse(current.node.creationSysDate);
                      const mostRecentDate = Date.parse(mostRecent.node.creationSysDate);
                      return currentDate > mostRecentDate ? current : mostRecent;
                    }
                  );
                const next = (x) => {
                    this.sLoading.hide();
                    UIHelper.openDialog(DisplayItemComponent, this.matDialog, {
                        entityName: "ValidationFormHistory",
                        module: "Presupuesto",
                        group: undefined
                    } as DataEntity, ENUM_ACTIONS.VIEW, x.id);
                };
                const error = (e) => {
                    this.sLoading.hide();
                    this.adfiGrowlService.errorMessage(e);
                };
                this.graphService.getFirstEntity('validationFormHistories',
                    'validDealObs',
                    `id: ${mostRecentHistory.node._id}`, {next, error});    
            }else{
                this.sLoading.hide();
                this.adfiGrowlService.warning("Alerta","Todavía no hay observaciones que mostrar");
            }
            
            
        };
        const error = (e) => {
            this.sLoading.hide();
            this.adfiGrowlService.errorMessage(e);
        };
        this.sLoading.show();
        this.graphService.getFirstEntity('validationForms',
            'validationFormHistories {edges {node {  id  _id  creationSysDate  statusForm  observation  __typename}__typename  }  __typename}',
            `cencosFilter_centroCosto: "${this.adfiService.user.centroCosto._id}"`, {next, error});
    }

}
