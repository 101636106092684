import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {AdfiService} from '@services/adfi-service';
import {LoadingService} from '@services/loading.service';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {ListComponent} from '@Components/ui/list/list.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {AdfiUtil} from '@Components/util/adfi-util';
import {FormComponent} from '@Components/ui/form/form.component';
import {DataEntity, Entities, ENUM_ACTIONS, Options, PeriodState, Permission} from '@Interfaces/index';
import {ConfirmDialogComponent, ConfirmDialogData} from '@Components/ui/confirm-dialog/confirm-dialog.component';
import {UIHelper} from '@Components/util/UIHelper';

@Component({
    selector: 'app-form-recursos-balance',
    templateUrl: './form-recursos-balance.component.html',
    styleUrls: ['./form-recursos-balance.component.css']
})
export class FormRecursosBalanceComponent implements OnInit {

    balanceResource: any;
    private balanceResourceReturn = 'id _id state valueBalanceResource confirmDataClosingForm certificate';
    totalCashBox: number;
    totalBalanceAccount: number;
    totalAccountPayment = 0;
    totalRegisters: number;
    totalClosingConciliation: number;
    totalBalanceResource: number;
    totalBalanceSource: number;
    totalBalanceSourceIngres: number;
    totalBalanceSourceExpenses: number;
    totalTreasuryPaymetOrderForm = 0;
    totalPassivesForm = 0;
    closingForm: any;
    totalPassivesNotRegisterForm = 0;

    accountBalances: {};


    @ViewChild('listCashBox') listCashBox: ListComponent;
    @ViewChild('listBalanceAccount') listBalanceAccount: ListComponent;
    @ViewChild('listAccountPayment') listAccountPayment: ListComponent;
    @ViewChild('listRegister') listRegister: ListComponent;
    @ViewChild('listBalanceSource') listBalanceSource: ListComponent;
    @ViewChild('listTreasuryOrder') listTreasuryOrder: ListComponent;
    @ViewChild('listPassives') listPassives: ListComponent;
    @ViewChild('listPassivesNotRegister') listPassivesNotRegister: ListComponent;
    @ViewChild('stepper') stepper: MatStepper;


    // permisos

    approveResourceBalance = false; // rol de lider de gestion financiera que aprueba los recursos de balance
    approveResourceBalanceAccount = false; // rol de contador SEC que aprueba los recursos de balance y pasan a revisión del lider

    approveCashBox = false;
    editValueExtract = false;
    approveAccountPayment = false;
    approveResources = false;
    approveTreasuryResources = false;
    approvePassives = false;
    approveConciliation = false;
    addBalanceSources = false;
    showHistory = false;
    private obs = '';

    canEditForm = false;
    canEditAnything = false;


    constructor(private adfiService: AdfiService, private loadingService: LoadingService, private graphqlService: AdfiGraphqlService,
                private adfiGrowlService: AdfiGrowlService, private matDialog: MatDialog) {
    }

    ngOnInit() {
        this.loadResource();
        this.loadTotalBalanceResource();
        this.approveResourceBalance = this.adfiService.validPermission(
            Entities.BALANCE_RESOURCE, Permission.APPROVE_MANAGER, PeriodState.CLOSE);
        this.approveResourceBalanceAccount = this.adfiService.validPermission(
            Entities.BALANCE_RESOURCE, Permission.APPROVE_ACCOUNT_MAN, PeriodState.CLOSE);
        this.canEditAnything = ((new Date()).getFullYear() - this.adfiService.getUserPeriod().year === 1);
    }

    validPermissionEdit(list: ListComponent, permission = Permission.EDIT) {
        return this.canEditAnything && this.adfiService.validPermission(
            list.entityName, permission, PeriodState.CLOSE)
            && (this.balanceResource.state === 'INI' || this.balanceResource.state === 'DCS');
    }

    addDataResource(data) {
        this.balanceResource = data;
        this.canEditForm = this.canEditAnything && (this.balanceResource.state === 'INI' || this.balanceResource.state === 'DCS');
        if (this.balanceResource !== undefined) {
            if (this.balanceResource.state === 'CLO' && (!this.approveResourceBalance && !this.approveResourceBalanceAccount)) {
                setTimeout(() => {
                    this.continue();
                }, 1000);
                this.loadingService.hide();
            } else if (!(this.balanceResource.state === 'INI' || this.balanceResource.state === 'DCS')
                && (!this.approveResourceBalance && !this.approveResourceBalanceAccount)) {
                setTimeout(() => {
                    this.continue();
                    this.continue();
                }, 1000);
                this.loadingService.hide();
            } else {
                this.loadForm();
            }
        } else {
            this.loadingService.hide();
        }
    }

    loadResource() {
        this.loadingService.show();
        const next = (data) => {
            if (data) {
                this.addDataResource(data);
            }
            this.loadingService.hide();
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.getFirstEntity('balanceResources',
            this.balanceResourceReturn,
            'cencosFilter_centroCosto: ""', {next, error});
    }

    loadForm() {
        const next = (data) => {
            if (data && data.egress) {
                this.closingForm = data;
                this.loadingService.hide();
            } else {
                setTimeout(() => {
                    this.continue();
                    this.loadingService.hide();
                }, 1000);
            }
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.getFirstEntity('closingForms',
            'id egress',
            'cencosFilter_centroCosto: ""', {next, error});
    }

    requestBalance() {
        this.loadingService.show();
        const next = (data) => {
            this.addDataResource(data);
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.createEntity('BalanceResource', {state: 'INI'},
            this.balanceResourceReturn, {next, error});
    }

    postLoadCashBox() {
        this.loadTotalBalanceResource();
        this.approveCashBox = this.validPermissionEdit(this.listCashBox);
        const next = (d) => {
            this.totalCashBox = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\CashBox',
            filters: JSON.stringify(this.listCashBox.getAllFilters()),
            actions: 'FR_BALANCE',
        }, 'total', {next, error});
    }

    postLoadAccounts() {
        this.loadTotalExtract();
        this.loadTotalConciliation();
        this.loadTotalBalanceResource();
    }

    loadTotalBalanceResource() {
        const next = (d) => {
            this.totalBalanceResource = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'BalanceResource\\BalanceResource',
            filters: JSON.stringify({
                cencos:
                    {class: 'ApiPlatform\\Core\\Bridge\\Doctrine\\Orm\\Filter\\NumericFilter', value: this.adfiService.user.centroCosto._id}
            }),
        }, 'total', {next, error});
    }

    loadTotalConciliation() {
        this.editValueExtract = this.validPermissionEdit(this.listBalanceAccount);
        this.approveConciliation = this.adfiService.validPermission(
            'ClosingConciliation', Permission.EDIT, PeriodState.CLOSE);
        const next = (d) => {
            this.totalClosingConciliation = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\AccountBalance',
            filters: JSON.stringify(this.listBalanceAccount.getAllFilters()),
            actions: 'loteconciliation',
        }, 'total', {next, error});
    }

    loadTotalExtract() {
        const next = (d) => {
            this.totalBalanceAccount = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\AccountBalance',
            filters: JSON.stringify(this.listBalanceAccount.getAllFilters())
        }, 'total', {next, error});
    }

    postLoadAccountPayment() {
        this.approveAccountPayment = this.validPermissionEdit(this.listAccountPayment);
        this.loadTotalBalanceResource();
        const next = (d) => {
            this.totalAccountPayment = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'Egresos\\PaymentOrder',
            filters: JSON.stringify(this.listAccountPayment.getAllFilters()),
            actions: 'FR_BALANCE',
        }, 'total', {next, error});
    }


    postLoadTreasuryPaymetOrder() {
        this.approveTreasuryResources = this.validPermissionEdit(this.listTreasuryOrder);
        this.loadTotalBalanceResource();
        const next = (d) => {
            this.totalTreasuryPaymetOrderForm = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\TreasuryPaymetOrderForm',
            filters: JSON.stringify(this.listTreasuryOrder.getAllFilters()),
            actions: 'FR_BALANCE',
        }, 'total', {next, error});
    }

    postLoadPassives() {
        this.approvePassives = this.adfiService.validPermission(this.listPassives.entityName, Permission.EDIT, PeriodState.CLOSE)
            && (this.balanceResource.state === 'UPL' || this.balanceResource.state === 'DENY');
        this.loadTotalBalanceResource();
        const next = (d) => {
            this.totalPassivesForm = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'BalanceResource\\PassivesBalance',
            filters: JSON.stringify(this.listPassives.getAllFilters()),
            actions: 'FR_BALANCE',
        }, 'total', {next, error});
    }

    postLoadPassivesNotRegisters() {
        this.loadTotalBalanceResource();
        const next = (d) => {
            this.totalPassivesNotRegisterForm = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'ClosingForm\\PaymentOrderWP',
            filters: JSON.stringify(this.listPassivesNotRegister.getAllFilters()),
            actions: 'FR_BALANCE',
        }, 'total', {next, error});
    }

    postLoadRegisters() {
        this.approveResources = this.validPermissionEdit(this.listRegister);
        this.loadTotalBalanceResource();
        const next = (d) => {
            this.totalRegisters = d.total ? d.total : 0;
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'Egresos\\Registro',
            filters: JSON.stringify(this.listRegister.getAllFilters()),
            actions: 'reservation',
        }, 'total', {next, error});
    }

    createItemConciliation(row) {

        const dialogRef = this.matDialog.open(LoteConciliationComponent, {
            width: '90%',
            maxHeight: '90vh',
            data: {
                account: row.account,
                editable: this.canEditAnything && this.canEditForm && this.approveConciliation,
                period: this.adfiService.user.period
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.listBalanceAccount.reload();
        });
    }

    postLoadBalanceSource() {
        this.loadTotalBalanceResource();
        this.loadTotalBalanceSource((d) => {
            this.totalBalanceSourceIngres = d.total ? d.total : 0;
        }, 'I');
        this.loadTotalBalanceSource((d) => {
            this.totalBalanceSourceExpenses = d.total ? d.total : 0;
        }, 'E');
        this.loadTotalBalanceSource((d) => {
            this.totalBalanceSource = d.total ? d.total : 0;
        }, 'T');
    }

    loadTotalBalanceSource(next, action?) {
        this.addBalanceSources = this.validPermissionEdit(this.listBalanceSource, Permission.ADD);
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
        };
        this.graphqlService.createEntity('Summation', {
            nameClass: 'BalanceResource\\BalanceSource',
            filters: JSON.stringify(this.listBalanceSource.getAllFilters()),
            actions: action,
        }, 'total', {next, error});
    }

    approveData(checked: boolean, row: any, listAction: ListComponent) {
        this.loadingService.show();
        const data = {id: row.id, approve: checked};
        const next = (d) => {
            if (d) {
                row.approve = d.approve;
                this.postLoadCashBox();
                this.postLoadAccountPayment();
                this.postLoadRegisters();
                this.postLoadAccounts();
                this.postLoadPassives();
                // this.loadTotalBalanceResource();
            }
            this.loadingService.hide();
        };
        const error = (e) => {
            this.loadingService.hide();
            this.adfiGrowlService.errorMessage(e, 'No se logro la actualización');
        };
        this.graphqlService.updateEntity(AdfiUtil.capitalizeFirstLetter(listAction.entityName), data, 'approve', {next, error}, listAction.configTable.table && listAction.configTable.table.options ? listAction.configTable.table.options[Options.NOT_ID] : false);
    }

    approvePassive(checked: boolean, row: any, listAction: ListComponent) {
        if (row.date) {
            this.approveData(checked, row, listAction);
        } else {
            this.loadingService.show();
            const data = {
                item: row.item,
                value: row.value,
                approve: checked
            };
            const next = () => {
                this.loadingService.hide();
                listAction.reload();
            };
            const error = (e) => {
                this.loadingService.hide();
                this.adfiGrowlService.errorMessage(e);
            };
            this.graphqlService.createEntity(listAction.entityName,
                data, '', {next, error}, listAction.configTable.table.options[Options.NOT_ID]);
        }
    }

    addObservationToPassive(row) {
        const ref = UIHelper.editItemEntity(this.matDialog, row.id, {
            entityName: this.listPassives.entityName,
            module: this.listPassives.module,
            group: 'create',
            customReturnAfterSave: ['objet'],
            customTitle: 'Observaciones de la aprobación'
        });
        ref.componentInstance.postSave.subscribe(d => {
           if (d) {
               row.objet = d.objet;
           }
        });
    }

    editExtract(row: any) {
        const dialogRef = this.matDialog.open(FormComponent, {
            width: '90%',
            maxHeight: '90vh',
            data: {
                entityName: this.listBalanceAccount.entityName,
                module: this.listBalanceAccount.module,
                item: row.id,
                action: ENUM_ACTIONS.EDIT,
                group: 'ADJU',
                customTitle: 'Ajustar Saldo Extracto'
            } as DataEntity
        });

        dialogRef.afterClosed().subscribe(result => {
            this.listBalanceAccount.reload();
        });
    }

    addSourceBalance(typePresu: string) {
        const dialogRef = this.matDialog.open(FormComponent, {
            width: '90%',
            maxHeight: '90vh',
            data: {
                entityName: this.listBalanceSource.entityName,
                module: this.listBalanceSource.module,
                action: ENUM_ACTIONS.CREATE,
                customData: {typePresu},
                group: typePresu,
                customTitle: typePresu === 'I' ? 'Agregar Ingreso' : 'Agregar Egreso',
            } as DataEntity
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.listBalanceSource.reload();
            }
        });
    }

    editSignature() {
        const dialogSignature = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'BalanceResource',
                module: 'BalanceResource',
                customTitle: 'Firmas Archivo',
                item: this.balanceResource.id,
                action: ENUM_ACTIONS.EDIT,
                customActionBtnText: 'Generar',
                group: 'signature'
            } as DataEntity
        });
        dialogSignature.afterClosed().subscribe(value => {
            if (value) {
                this.generateReport();
            }
        });
    }

    closForm() {
        const dialog = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Finalizar diligenciamiento',
                content: `<p>¿Está seguro/a de finalizar el diligenciamiento?</p><p>Tenga en cuenta que una vez finalizado el diligenciamiento <span class="alert-color"><strong>no podrá modificar la información registrada</strong></span>, por lo que se recomienda validar los datos suministrados mediante el documento en borrador generado por el boton <strong>Generar Archivo Borrador</strong></p>`,
                action: ENUM_ACTIONS.DELETE
            } as ConfirmDialogData
        });
        dialog.beforeClosed().subscribe((ok) => {
            if (ok) {
                this.changeStatusBalance('CLO');
            }
        });
    }

    changeStatusBalance(state) {
        this.loadingService.show();
        const next = (data) => {
            this.balanceResource = data;
            this.loadingService.hide();
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.mutationEntity('BalanceResource', {id: this.balanceResource.id, state},
            this.balanceResourceReturn, ENUM_ACTIONS.EDIT, {next, error});
    }

    generateReport() {
        this.adfiService.download(
            'BalanceResource\\BalanceResource',
            JSON.stringify({}),
            'fileName',
            'application/pdf',
            'RECURSO-BALANCE-2020.pdf');
    }

    uploadFile() {
        const dialogAdd = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'BalanceResource',
                module: 'BalanceResource',
                customTitle: 'Subir Archivo Firmado',
                item: this.balanceResource.id,
                action: ENUM_ACTIONS.EDIT,
                group: 'upload_file'
            } as DataEntity
        });
        dialogAdd.afterClosed().subscribe(value => {
            if (value) {
                this.changeStatusBalance('UPL');
                this.continue();
            }
        });
    }

    continue() {
        this.stepper.next();
    }

    approveResource() {
        const dialog = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Aprobar Recurso de Balance',
                content: `<p>¿Está seguro/a de Aprobar?</p><p>Tenga en cuenta que una vez aprobado el recurso de balance no podrá modificar la información registrada, y se le enviará un correo a la Institución Educativa.</p>`,
                action: ENUM_ACTIONS.DELETE
            } as ConfirmDialogData
        });
        dialog.beforeClosed().subscribe((ok) => {
            if (ok) {
                this.approveBalanceResource('APPRO');
            }
        });
    }


    approveResourceAccount() {
        const dialogApprove = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'HistoryResource',
                module: 'BalanceResource',
                customTitle: 'APROBAR RECURSO DE BALANCE',
                action: ENUM_ACTIONS.CREATE,
                customActionBtnText: 'APROBAR',
                customData: {
                    balanceResource: this.balanceResource.id,
                    state: 'ACS',
                }
            } as DataEntity,
            minWidth: '70%'
        });
        dialogApprove.componentInstance.preLoad.subscribe((col) => {
            const control = col.control;
            if (col.column.fieldName === 'message') {
                control.assignedNatives.subscribe(() => {
                    col.column.label = 'Observaciones (Tenga en cuenta que estas observaciones se verán reflejadas en el documento de autorización)';
                    control.clearValidators();
                    control.setValidators(col.validators.splice(0, 1));
                    control.updateValueAndValidity();
                    if (this.obs) {
                        control.setValue(this.obs);
                    }
                });
            }
        });
        dialogApprove.afterClosed().subscribe(value => {
            if (value) {
                this.loadResource();
            }
        });
    }

    denyResource(state) {
        const dialogDeny = this.matDialog.open(FormComponent, {
            data: {
                entityName: 'HistoryResource',
                module: 'BalanceResource',
                customTitle: 'DENEGAR RECURSO DE BALANCE',
                action: ENUM_ACTIONS.CREATE,
                customActionBtnText: 'DENEGAR',
                customData: {
                    balanceResource: this.balanceResource.id,
                    state
                }
            } as DataEntity
        });
        dialogDeny.afterClosed().subscribe(value => {
            if (value) {
                this.loadResource();
            }
        });
    }

    private approveBalanceResource(state) {
        this.loadingService.show();
        const next = (data) => {
            this.adfiGrowlService.info('Recurso de Balance', 'Recurso de balance aprobado');
            this.loadingService.hide();
            this.loadResource();
        };
        const error = (e) => {
            this.adfiGrowlService.errorMessage(e);
            this.loadingService.hide();
        };
        this.graphqlService.mutationEntity('HistoryResource', {balanceResource: this.balanceResource.id, state},
            'id', ENUM_ACTIONS.CREATE, {next, error});
    }

    enableHistory() {
        this.showHistory = !this.showHistory;
    }

    downloadAuthorization(draw = false) {
        let obs = '';
        if (draw) {
            const dialogRef = this.matDialog.open(DialogPreApproveComponent, {
                width: '800px',
                data: {obs: this.obs}
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== undefined) {
                    obs = result;
                    this.obs = obs;
                    const filter = {obs: {class: 'ApiPlatform\\Core\\Bridge\\Doctrine\\Orm\\Filter\\SearchFilter', value: obs}};
                    this.adfiService.download(
                        'BalanceResource\\BalanceResource',
                        JSON.stringify(filter),
                        'fileName',
                        'application/pdf',
                        'autorizacionAdicion.pdf', draw ? 'draw-authorization' : 'authorization');
                }
            });
        } else {
            this.adfiService.download(
                'BalanceResource\\BalanceResource',
                JSON.stringify({}),
                'fileName',
                'application/pdf',
                'autorizacionAdicion.pdf', draw ? 'draw-authorization' : 'authorization');
        }
    }

    downloadRequest() {
        AdfiUtil.downloadMediaObject(this.balanceResource.certificate ,
            { fieldName: 'certificate', options: {bucket: 'files_end_period'}}, this.adfiService.user.period);
    }

}


@Component({
    selector: 'app-list-lote-conciliation',
    templateUrl: 'list-lote-conciliation.component.html',
})
export class LoteConciliationComponent {

    constructor(
        public dialogRef: MatDialogRef<LoteConciliationComponent>,
        @Inject(MAT_DIALOG_DATA) public data, private loadingService: LoadingService,
        private adfiGrowlService: AdfiGrowlService, private graphqlService: AdfiGraphqlService) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    updateConciliation(checked: boolean, row: any, cncList: ListComponent) {
        this.loadingService.show();
        const data = {id: row.id, approve: checked};
        const next = (d) => {
            if (d) {
                row.approve = d.approve;
            }
            this.loadingService.hide();
        };
        const error = (e) => {
            this.loadingService.hide();
            this.adfiGrowlService.errorMessage(e, 'No se logro la actualización');
        };
        this.graphqlService.updateEntity(AdfiUtil.capitalizeFirstLetter(cncList.entityName), data, 'approve', {next, error});
    }
}
@Component({
    selector: 'app-dialog-pre-approve',
    templateUrl: 'dialog-pre-approve.component.html',
})
export class DialogPreApproveComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogPreApproveComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}

    onNoClick(): void {
        this.dialogRef.close();
    }

}
