import {Component, ComponentFactoryResolver, Injector, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DataEntity, ENUM_ACTIONS} from '@Interfaces/index';
import {LoadingService} from '@services/loading.service';
import {AdfiService} from '@services/adfi-service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AdfiGraphqlService} from '@services/adfi-graphql.service';
import {AdfiGrowlService} from '@services/adfi-growl.service';
import {FormComponent} from '@Components/ui/form/form.component';
import {ConfirmDataComponent} from '@pages/public/confirm-data/confirm-data.component';
import {MyTel} from '@Components/ui/tel-input/tel-input.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

  @ViewChild('tem_RegisterForm', { read: ViewContainerRef }) vRegisterForm: ViewContainerRef;
  registerForm: FormComponent;

  training: any;
  textBtn: string;
  reservation: any;

  constructor(private injector: Injector,
              private componentFactoryResolver: ComponentFactoryResolver,
              private loadingService: LoadingService,
              public adfiService: AdfiService,
              private matDialog: MatDialog,
              private sanitizer: DomSanitizer,
              private graphqlService: AdfiGraphqlService,
              private adfiGrowlService: AdfiGrowlService) { }

  ngOnInit(): void {
    this.loadTraining();
  }

  loadTraining() {
    this.loadingService.show();
    const now = new Date();
    const next = (val) => {
      this.training = val;
      if (this.training) {
        this.loadReservation();
      } else {
        this.loadingService.hide();
      }
    };
    const error = (val) => {
      this.adfiGrowlService.errorMessage(val);
      this.loadingService.hide();
    };
    this.graphqlService.getFirstEntity('trainings',
        'id _id period confirnmationMail titleTraining contactNumber canRegister desactivationDate activities{edges{node{startDate endDate nameActivity description responsible}}} recomendations{edges{node{textRecomendation}}}',
        `activationDate:{before:"${now.toUTCString()}"}, retirementDate:{after:"${now.toUTCString()}"}`, {next, error});
  }

  loadReservation() {
    this.loadingService.show();
    const next = (val) => {
      this.reservation = val;
      if (this.reservation){
        this.reservation.schedule.linkSession = this.sanitizer.bypassSecurityTrustUrl(this.reservation.schedule.linkSession);
        if (!this.reservation && this.training.canRegister) {
          this.buildFormReservation(val ? val.id : null);
        }
      }
      this.loadingService.hide();
    };
    const error = (val) => {
      this.adfiGrowlService.errorMessage(val);
      this.loadingService.hide();
    };
    this.graphqlService.getFirstEntity('reservations',
        'id _id numberClickToken fileSupport name lastname personalEmail schedule{startDate linkSession } canUploadAct surveyComplete',
        `training_id:${this.training._id}, employee_id: ${this.adfiService.user.id}`, {next, error});
  }

  buildFormReservation(id?) {
      const dataForm = {
          entityName: 'Reservation',
          module: 'Trainings',
          customActionBtnText: 'Continuar',
          customTitle: 'Registro Capacitación',
          displayBtnCancel: false,
          displayBtnSave: false,
          group: 'RT',
      } as DataEntity;
      if (id) {
        dataForm.action = ENUM_ACTIONS.EDIT;
        dataForm.item = id;
        this.textBtn = 'Editar Registro';
      } else {
        dataForm.action = ENUM_ACTIONS.CREATE;
        this.textBtn = 'Registrarme';
        dataForm.initialData = {name: this.adfiService.user.name, lastname: this.adfiService.user.apellidos,
          documento: this.adfiService.user.document};
        dataForm.customData = {training: this.training.id };
        dataForm.canEditAutoAssign = ['name', 'lastname', 'documento'];
      }
      this.registerForm = this.createForm(this.vRegisterForm, dataForm);
      this.loadingService.hide();
  }

  createForm(form: ViewContainerRef, dataDialog: DataEntity): FormComponent {
    form.clear();
    const inj: Injector = this.makeCustomInjector(dataDialog);
    const resolver = this.componentFactoryResolver.resolveComponentFactory(FormComponent);
    return form.createComponent(resolver, 0, inj).instance;
  }

  makeCustomInjector(dataDialog) {
    return Injector.create({
      providers: [{
        provide: MatDialogRef, useValue: {}
      }, {
        provide: MAT_DIALOG_DATA, useValue: dataDialog
      }],
      parent: this.injector
    });
  }

  register() {
    const phone =  this.registerForm.entityForm.get('phone').value as MyTel;
    const email = this.registerForm.entityForm.get('personalEmail').value;
    const cellphone = phone ? '+' + phone.area + ' ' + phone.isp + phone.exchange + phone.subscriber : '';
    const dialogRef = this.matDialog.open(ConfirmDataComponent, {
      data: {
        ie: this.adfiService.user.centroCosto,
        role: this.adfiService.user.role._id,
        names: this.registerForm.entityForm.get('name').value,
        lastnames: this.registerForm.entityForm.get('lastname').value,
        document: this.registerForm.entityForm.get('documento').value,
        email,
        cellphone
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.registerForm.save(this.registerForm.form);
          this.registerForm.postSave.subscribe((a) => {
            this.loadingService.show();
            this.loadReservation();
          });
        }
      });
  }

  getDocumento() {
    this.adfiService.download( 'Trainings\\Reservation',
        JSON.stringify({id: this.reservation._id}), 'fileName', 'application/pdf',
        ('Soporte de Asistencia a Capacitación')
            .replace(/\s/g, '_')  + '.pdf',  'printItem');
  }

  uploadFile() {
    const dialogAdd = this.matDialog.open(FormComponent, {
      data: {
        entityName: 'Reservation',
        module: 'Trainings',
        customTitle: 'Subir Archivo Firmado',
        item: this.reservation.id,
        action: ENUM_ACTIONS.EDIT,
        group: 'UPLA'
      } as DataEntity
    });
    dialogAdd.afterClosed().subscribe(value => {
          if (value) {
            this.loadReservation();
          }
        });

  }

  openSatisfactionSurvey() {
    const dialogAdd = this.matDialog.open(FormComponent, {
      data: {
        entityName: 'SatisfactionSurveyForm',
        module: 'Trainings',
        action: ENUM_ACTIONS.CREATE,
        customTitle: 'Encuesta de satisfacción',
        customData: { training: this.training._id }
      } as DataEntity,
      maxHeight: '90vh',
      minWidth: '90vh'
    });
    dialogAdd.afterClosed().subscribe(value => {
      this.loadReservation();
    });
  }
}
