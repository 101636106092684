import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {NavRoute} from 'src/app/utils/menu';

import {PrminiPresupuestoComponent} from './prmini-presupuesto/prmini-presupuesto.component';

import {PresupuestoInicialComponent} from './presupuesto-inicial/presupuesto-inicial.component';
import {RubrosPresupuestoComponent} from './rubros-presupuesto/rubros-presupuesto.component';
import {RubrosPresupuestoEgresoComponent} from './rubros-presupuesto/rubros-presupuesto-egreso.component';
import {Entities, Pages, Permission} from '@Interfaces/index';
import {AuxiliaryBudgetBooksComponent} from '@pages/presupuesto/auxiliary-budget-books/auxiliary-budget-books.component';
import {BudgetExecutionComponent} from '@pages/presupuesto/budget-execution/budget-execution.component';
import {EndReservationsComponent} from '@pages/presupuesto/end-reservations/end-reservations.component';
import {RequestReserveComponent} from '@pages/presupuesto/request-reserves/request-reserve.component';
import {BudgetReserveLedgerComponent} from '@pages/presupuesto/budget-reserve-ledger/budget-reserve-ledger.component';
import { PlanAnualAdquisicionComponent } from './plan-anual-adquisicion/plan-anual-adquisicion.component';
import {FormularioValidacionPiPaaComponent} from '@pages/presupuesto/formulario-validacion-pi-paa/formulario-validacion-pi-paa.component'

export const RoutesPresupuesto: NavRoute[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: Pages.BUDGET_INITIAL
  }, {
    path: Pages.BUDGET_INITIAL,
    component: PresupuestoInicialComponent,
    entity: 'PresupuestoInicial',
    permission: Permission.VIEW_ALL
  }, {
    path: Pages.BUDGET_SUMMARY,
    nav: { title: 'Presupuesto Inicial', icon: 'account_tree' },
    component: PrminiPresupuestoComponent,
    entity: 'PresupuestoInicial'
  },
  // NEW: Plan Anual de Adquisiciones
  {
      path: Pages.ANNUAL_ACQUISITION_PLAN,
      nav: { title: 'Plan Anual de Adquisiciones', icon: 'attach_money' },
      component: PlanAnualAdquisicionComponent,
      entity: 'PresupuestoInicial',
  },
  {
    path: Pages.BUDGET_INCOME,
    nav: { title: 'Presupuesto de Ingresos', icon: 'attach_money' },
    component: RubrosPresupuestoComponent,
    entity: 'PresupuestoInicial',
    permission: Permission.VIEW_INCOME
  }, {
    path: Pages.BUDGET_EXPENSES,
    nav: { title: 'Presupuesto de Egresos', icon: 'money_off' },
    component: RubrosPresupuestoEgresoComponent,
    entity: 'PresupuestoInicial',
    permission: Permission.VIEW_EXPENSES
  },
  {
    path: Pages.SOLICITUDE_RESERVATIONS,
    component: EndReservationsComponent
  },
  {
    path: Pages.REPORTS_AUX_BUDGET_BOOKS,
    component: AuxiliaryBudgetBooksComponent,
    entity: Entities.AUXILIARY_BUDGET_BOOKS
  },
  {
    path: Pages.REPORTS_BUDGET_EXECUTION,
    component: BudgetExecutionComponent,
    entity: Entities.BUDGET_EXECUTION
  },
  {
    path: Pages.SOLICITUDE_LIST_RESERVATIONS,
    component: RequestReserveComponent,
    entity: Entities.REQUEST_RESOURCE,
    permission: Permission.VIEW_ALL
  },
  {
    path: Pages.BUDGET_RESERVE_LEDGER,
    component: BudgetReserveLedgerComponent,
    entity: Entities.RESERVE,
    permission: Permission.VIEW,
    data: {type: 'ReserveBook'}
  },
  {
    path: Pages.ACCOUNT_PAYMENT_LEDGER,
    component: BudgetReserveLedgerComponent,
    entity: Entities.PAYMENT_ORDER_WP,
    permission: Permission.LIST,
    data: {type: 'CXPBook'}
  },
  {
    path: Pages.VALIDATION_FORM_PATH,
    component: FormularioValidacionPiPaaComponent,
    entity: Entities.VALIDATION_FORM,
    permission: Permission.ADD,
    // periodSate: PeriodState.NO_STATUS
  },
];

@NgModule({
  imports: [RouterModule.forChild(RoutesPresupuesto)],
  exports: [RouterModule]
})
export class PresupuestoRoutingModule { }
