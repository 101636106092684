import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { PresupuestoRoutingModule } from './presupuesto-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ShareModule } from 'src/app/share/share.module';
import { PrminiPresupuestoComponent } from './prmini-presupuesto/prmini-presupuesto.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ViewPresupuestoInicialComponent } from '@Components/presupuesto/view-presupuesto-inicial/view-presupuesto-inicial.component';
import { TreePresupuestoComponent } from '@Components/presupuesto/tree-presupuesto/tree-presupuesto.component';
import { PresupuestoInicialComponent } from './presupuesto-inicial/presupuesto-inicial.component';
import { RubrosPresupuestoComponent } from './rubros-presupuesto/rubros-presupuesto.component';
import { RubrosPresupuestoEgresoComponent } from './rubros-presupuesto/rubros-presupuesto-egreso.component';
import {UiModule} from '@Components/ui/ui.module';
import {UtilModule} from '@Components/util/util.module';
import {MatChipsModule} from '@angular/material/chips';
import { AuxiliaryBudgetBooksComponent } from './auxiliary-budget-books/auxiliary-budget-books.component';
import {TableModule} from '@Components/table/table.module';
import {DateRangeSelectionComponent} from '@Components/date-range-selection/date-range-selection.component';
import {DateRangeSelectionModule} from '@Components/date-range-selection/date-range-selection.module';
import { BudgetExecutionComponent } from './budget-execution/budget-execution.component';
import {EndReservationsComponent} from '@pages/presupuesto/end-reservations/end-reservations.component';
import {MatStepperModule} from '@angular/material/stepper';
import {EditorModule} from '@tinymce/tinymce-angular';
import {RequestReserveComponent} from '@pages/presupuesto/request-reserves/request-reserve.component';
import { BudgetReserveLedgerComponent } from './budget-reserve-ledger/budget-reserve-ledger.component';
import {MatRippleModule} from '@angular/material/core';
import { PlanAnualAdquisicionComponent } from './plan-anual-adquisicion/plan-anual-adquisicion.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import { FormularioValidacionPiPaaComponent , ObservationDialogComponent} from './formulario-validacion-pi-paa/formulario-validacion-pi-paa.component';


@NgModule({
    declarations: [
        ViewPresupuestoInicialComponent,
        ObservationDialogComponent,
        PrminiPresupuestoComponent,
        TreePresupuestoComponent,
        PresupuestoInicialComponent,
        RubrosPresupuestoComponent,
        RubrosPresupuestoEgresoComponent,
        AuxiliaryBudgetBooksComponent, BudgetExecutionComponent,
        EndReservationsComponent, RequestReserveComponent, BudgetReserveLedgerComponent, PlanAnualAdquisicionComponent, FormularioValidacionPiPaaComponent
    ],
    entryComponents: [
        ObservationDialogComponent, ViewPresupuestoInicialComponent, DateRangeSelectionComponent
    ],
    exports: [
        TreePresupuestoComponent
    ],
    imports: [
        CommonModule,
        PresupuestoRoutingModule,
        ShareModule,
        // flex
        FlexLayoutModule,
        // mat
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        MatDatepickerModule,
        MatTableModule,
        MatProgressBarModule,
        MatRadioModule,
        FormsModule,
        MatTooltipModule,
        MatTreeModule,
        UiModule,
        UtilModule,
        MatChipsModule,
        TableModule,
        UtilModule,
        DateRangeSelectionModule,
        MatStepperModule,
        EditorModule,
        MatRippleModule,
        MatMenuModule,
        MatListModule
    ]
})
export class PresupuestoModule { }
