<div class="container">
    <div class="row">
        <mat-form-field class="col-12 col-md-2">
            <mat-label>Vigencia</mat-label>
            <mat-select placeholder="Seleccionar" [(ngModel)]="currentPeriod" (selectionChange)="selectAllConciliation(bankAccount)">
                <mat-option *ngFor="let period of periods" [value]="period.year">{{ period.year }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12 col-md-10">
            <mat-label>Cuenta</mat-label>
            <mat-select placeholder="Seleccionar" (selectionChange)="selectAllConciliation($event.value)">
                <mat-select-trigger>
                    <ng-container *ngIf="bankAccount">
                        <mat-icon *ngIf="bankAccount.vMaint === 'D'" class="block-noty" color="warn" matTooltip="Cuenta CANCELADA">block</mat-icon>&nbsp;
                        <b>N° </b>({{ bankAccount.numero }})
                        <b> - </b>{{ bankAccount.nombre}}
                    </ng-container>
                </mat-select-trigger>
                <mat-option *ngFor="let cuenta of bankAccounts" [value]="cuenta">{{ cuenta.nombre }}
                    {{ cuenta.numero }}
                    <mat-icon *ngIf="cuenta.vMaint === 'D'" color="warn" matTooltip="Cuenta CANCELADA">block</mat-icon>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="row" *ngIf="currentPeriod && bankAccount">
        <div class="col-4">
            <mat-form-field class="full-width">
                <mat-label>Conciliación</mat-label>
                <mat-select placeholder="Conciliaciones" (selectionChange)="selectConciliation($event.value)">
                    <mat-option *ngFor="let conciliacion of allConciliation " [value]="conciliacion">
                        {{ conciliacion.fechaInicial | date:'dd/MM/yyyy' }} -
                        {{ conciliacion.fechaFinal | date:'dd/MM/yyyy' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-auto">
            <button mat-raised-button *ngIf="canStore" (click)="newConciliation()" color="primary">Agregar Conciliación</button>
            <button mat-raised-button
                    *ngIf="canRemove && conciliation && (!conciliation.estado || conciliation.estado == 'I' )"
                    (click)="removeConciliation()" color="warn">
                <mat-icon>delete</mat-icon> Eliminar Conciliación
            </button>
        </div>
        <div class="col-auto" *ngIf="conciliation">
            <button mat-raised-button (click)="exportConciliation(false)">
                <mat-icon>insert_drive_file</mat-icon>Exportar
            </button>
            <button mat-raised-button (click)="exportConciliation(true)">
                <mat-icon>file_copy</mat-icon> Exportar Todo
            </button>
            <button *ngIf="!conciliation.estado && conciliation.diferencia==0 && canApprove" mat-raised-button
                    (click)="approveConciliation()">
                <mat-icon>check</mat-icon> Aprobar Conciliación
            </button>
            <!--<button *ngIf="!conciliation.documentoSoporte && canUpload" mat-raised-button
                    (click)="documentSupport()">
                <mat-icon>insert_drive_file</mat-icon> Documento Soporte
            </button>
            <button *ngIf="conciliation.documentoSoporte && canDownload" mat-raised-button
                    (click)="downloadFile()">
                <mat-icon>cloud_download</mat-icon> Descargar Documento Soporte
            </button>-->
        </div>
    </div>
    <mat-card class="full-width mb-2" *ngIf="conciliation">
        <div class="row">
            <div class="col"><strong> Inicial: </strong>{{ conciliation.saldoInicial | currency }}</div>
            <div class="col"><strong> Saldo Extracto: </strong> {{ conciliation.saldoFinal | currency }}</div>
            <div class="col"><strong> Diferencia: </strong>{{ conciliation.diferencia | currency }}</div>
            <div class="col"><strong>Saldo Conciliado:</strong></div>
            {{ conciliation.diff | currency }}
            <div class="col" [ngSwitch]="conciliation.estado">
                <strong> Estado: </strong>
                <span *ngSwitchCase="'A'">Aprobado</span>
                <span *ngSwitchCase="'C'">Cerrado</span>
                <span *ngSwitchDefault>Inicial</span>
            </div>
        </div>
    </mat-card>

    <ng-container *ngIf="conciliation">
        <div class="row">
            <!--div class="col-12 text-right" *ngIf="!conciliation.estado && moveTable">
                <button matTooltip="Conciliar movimientos, Incluye filtros"
                        [disabled]="moveTable.loadingData" color="primary" mat-stroked-button
                        (click)="conciliarMovements('S')">
                    Conciliar Todo
                </button>
                <button matTooltip="Cancelar movimientos, Incluye filtros"
                        [disabled]="moveTable.loadingData" color="warn" mat-stroked-button
                        (click)="conciliarMovements('N')">
                    Cancelar Todos
                </button>
            </div-->
            <div class="col-12">
                <app-list #moveTable entityName="MovimientoBancario" module="Tesoreria"
                          [customQuery]="[{column: 'fecha', value: [conciliation.fechaInicial, conciliation.fechaFinal]}]"
                          [defaultQuery]="[ {column: 'cuenta', value: bankAccount.id}]"
                          (loadedData)="changeMovements($event, moveTable)">
                    <ng-template tableColumn="conciliado" let-row="row">
                        <ng-container *ngIf="row.conciliado == 'S'">
                            <mat-icon>done</mat-icon>
                            <button *ngIf="!conciliation.estado" mat-stroked-button color="warn"
                                    (click)="conciliar(row,'N')">Cancelar</button>
                        </ng-container>
                        <button *ngIf="row.conciliado == 'N' && !conciliation.estado" class="text-center" mat-raised-button
                                (click)="conciliar(row,'S')">Conciliar</button>
                    </ng-template>
                    <ng-template tableColumn="valor" let-row="row">
                        {{ (row.tipo == 'D' ? row.valor : -row.valor) | currency}}
                    </ng-template>
                    <ng-template tableColumn="footer_valor">
                        <strong> {{ data.sumMovements | currency}}</strong>
                    </ng-template>
                </app-list>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <app-list #part entityName="PartidaConciliacion" module="Tesoreria"
                          [customQuery]="[{column: 'fecha', value: [this.conciliation.fechaInicial, this.conciliation.fechaFinal]}]"
                          [defaultQuery]="[
                            {column: 'cuenta', value: this.bankAccount.id}
                          ]"
                          (formShow)="dialog($event)"
                          (loadedData)="changeParts($event, part)">
                    <ng-template tableColumn="conciliado" let-row="row">
                        <ng-container *ngIf="row.conciliado == 'B'">
                            <button mat-icon-button title="Conciliar"
                                    (click)="conciliarPart(row,'S')">
                                <mat-icon>done</mat-icon>
                            </button>
                            <button mat-icon-button title="Eliminar"
                                    (click)="conciliarPart(row, 'N')">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="row.conciliado == 'S'">
                            <button mat-icon-button title="Cancelar" color="warn"
                                    (click)="cancelPart(row, part)">
                                <mat-icon>clear</mat-icon>
                            </button>
                        </ng-container>
                    </ng-template>
                    <ng-template tableColumn="valor" let-row="row">
                        {{ (row.tipo == 'D' ? row.valor : -row.valor) | currency}}
                    </ng-template>
                    <ng-template tableColumn="footer_valor">
                        <strong class="field-currency"> {{ data.sumParts | currency}}</strong>
                    </ng-template>
                </app-list>
            </div>
        </div>
    </ng-container>
</div>
